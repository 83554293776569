import React from 'react';
import Storage from '../common/Storage';
import axios from 'axios';
import Constants from '../common/Constants';
import SimpleModal from '../common/SimpleModal';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import FroalaEditor from 'react-froala-wysiwyg';

class Reminder extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            reminder: ""
        };
    }

    GetReminder = () => {
        axios.get('/assets/appfiles/reports/' + this.GetFileName())
            .then(res => { // then print response status
                //console.log(res);
                this.setState({ reminder: res.data });
            })
            .catch((error) => {
                let reminder = "<i>No se han creado recordatorios para este condominio</i>";
                this.setState({ reminder });

                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
    }

    //componentDidUpdate(prevProps, prevState, snapshot) {
    componentDidMount() {
        //if (prevProps.residentialId == this.props.residentialId) { return; }

        this.GetReminder();
    }

    GetFileName = () => { return "Futurecr_Reminder_" + this.props.residentialId + ".html"; }

    render() {
        return (
            <div>
                <h4>
                    <span className="mr-2">Recordatorios</span>
                </h4>
                <hr />
                <div dangerouslySetInnerHTML={{ __html: this.state.reminder }} />
            </div>
        );
    }
}

export default Reminder;