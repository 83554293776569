import React from 'react';
import Storage from '../common/Storage';
import axios from 'axios';
import Constants from '../common/Constants';
import AdminUsers from './AdminUsers';

class AdminBuildingItem extends React.Component {
    constructor(props) {
        super(props);

        let building = this.props.building;
        this.state = {
            building,
        };
    }

    componentDidMount() {
        this.props.setSave(this.Save);
    } 

    Save = () => {
        if (this.props.action == Constants.STATUS_EDIT) { this.Update(); }
        if (this.props.action == Constants.STATUS_NEW) { this.Create(); }
    }

    Update = () => {
        this.SaveAPI("Update","put",this.state.building);
    }
    Create = () => {
        this.SaveAPI("New", "post", this.state.building);
    }

    SaveAPI = (action, method, data) => {
        //console.log(data.isReservationRestricted);
        //data.isReservationRestricted = (data.isReservationRestricted == "true");
        axios(Constants.URL_API + 'Building/' + action, { method, data, headers: Storage.getAuth() })
            .then(res => {
                if (this.props.onBuildingSaved) { this.props.onBuildingSaved(); }
                if (this.SaveUsers) { this.SaveUsers(); }
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
    }

    CodeChanged = (e) => {
        let building = this.state.building;
        building.code = e.target.value;
        this.setState({ building, rowStatus: Constants.STATUS_EDIT });
    }

    AuthorizedGuestsChanged = (e) => {
        let building = this.state.building;
        building.authorizedGuests = e.target.value;
        this.setState({ building, rowStatus: Constants.STATUS_EDIT });
    }

    OwnersChanged = (e) => {
        let building = this.state.building;
        building.owners = e.target.value;
        this.setState({ building, rowStatus: Constants.STATUS_EDIT });
    }

    IsReservationRestrictedChanged = (e) => {
        let building = this.state.building;
        building.isReservationRestricted = e.target.checked;
        this.setState({ building, rowStatus: Constants.STATUS_EDIT });
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col-sm-3">Filial: </div>
                    <div className="col-sm-9">
                        <input type="text" className="form-control" value={this.state.building.code} onChange={this.CodeChanged} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-3">Propietarios: </div>
                    <div className="col-sm-9">
                        <textarea className="form-control" value={this.state.building.owners} onChange={this.OwnersChanged} rows="6" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-3">Autorizados: </div>
                    <div className="col-sm-9">
                        <textarea className="form-control" value={this.state.building.authorizedGuests} onChange={this.AuthorizedGuestsChanged} rows="6" />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-3">Restriccion para reservar: </div>
                    <div className="col-sm-9">
                        <input type="checkbox" checked={this.state.building.isReservationRestricted} onChange={this.IsReservationRestrictedChanged}  />
                    </div>
                </div>
                <hr />
                {this.props.action == Constants.STATUS_EDIT &&
                    <AdminUsers buildingId={this.props.building.buildingId} setSave={s => this.SaveUsers = s} />
                }
            </>
        );
    }
}

export default AdminBuildingItem;