import React from 'react';
import Storage from '../common/Storage';
import axios from 'axios';
import Constants from '../common/Constants';
import DataTable from 'react-data-table-component';
import SimpleModal from '../common/SimpleModal';
import AdminBuildingItem from './AdminBuildingItem';
import ReservationHistory from '../reserve/ReservationHistory';

class AdminBuildings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentBuilding: {},
            showModalBuilding: false,
            showModalReservations: false,
            buildingList: [],
            columns: [
                { name: 'Filial', selector: 'code', sortable: true, left: true, width: "10%" },
                {
                    name: 'Propietarios', selector: 'owners', sortable: true, left: true, width: "30%", cell: row => (
                        <div style={{ width: "200px" }}>
                            {row.owners}
                        </div>
                    )
                },
                {
                    name: 'Visitas Autorizadas', selector: 'authorizedGuests', sortable: true, left: true, width: "30%", cell: row => (
                        <div style={{ width: "200px" }}>
                            {row.authorizedGuests}
                        </div>
                        )
                },
                {
                    name: 'Restriccion de reservar', selector: 'isReservationRestricted', sortable: true, left: true, cell: row => (
                        <div>
                            {row.isReservationRestricted &&
                                <span className="fa fa-ban"></span>
                                }
                        </div>
                    )
                },
                {
                    name: 'Acciones', right: true, cell: row => (
                        <div>
                            <button className="btn btn-sm btn-info" onClick={() => { this.openModalEdit(row); }}><span className="fa fa-pencil"></span></button>
                            <button className="btn btn-sm btn-primary" onClick={() => { this.openModalReservations(row); }}><span className="fa fa-tags"></span></button>
                        </div>
                    )
                },
            ],
        };
    }

    openModalEdit = (row) => {
        this.setState({
            currentBuilding: row,
            showModalBuilding: true,
            modalOkText: "Guardar cambios",
            modalCancelText: "Descartar cambios",
            action: Constants.STATUS_EDIT
        });
    }

    openModalNew = () => {
        this.setState({
            currentBuilding: {
                residential: { residentialId: this.props.residentialId}
            },
            showModalBuilding: true,
            modalOkText: "Guardar cambios",
            modalCancelText: "Descartar cambios",
            action: Constants.STATUS_NEW
        });
    }

    openModalReservations = (row) => {
        this.setState({
            showModalReservations: true,
            selectedBuildingId: row.buildingId,
            modalCancelText: "Cerrar",
        });
    }

    modalOkClick = () => {
        this.SaveBuilding();
    }

    UpdateBuildings = () => {
        axios.get(Constants.URL_API + 'Building/GetAll?Id=' + this.props.residentialId, { headers: Storage.getAuth() })
            .then(res => {
                this.setState({ buildingList: res.data });
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.residentialId == this.props.residentialId) { return; }
        this.UpdateBuildings();
    }

    OnBuildingSaved = () => {
        this.setState({ savedModalShow: true });
    }

    render() {
        return (
            <div>
                <SimpleModal body="Los cambios se han guardado correctamente" okText="Gracias!" show={this.state.savedModalShow} onClose={() => { this.setState({ savedModalShow: false }); this.UpdateBuildings(); }} />
                <SimpleModal dialogClassName="modal-90w" show={this.state.showModalBuilding} okClick={this.modalOkClick} okText={this.state.modalOkText} cancelText={this.state.modalCancelText} onClose={() => { this.setState({ showModalBuilding: false }) }}>
                    <AdminBuildingItem building={this.state.currentBuilding} action={this.state.action} setSave={s => this.SaveBuilding = s} onBuildingSaved={this.OnBuildingSaved} />
                </SimpleModal>
                <SimpleModal dialogClassName="modal-90w" show={this.state.showModalReservations} okClick={this.modalOkClick} okText={this.state.modalOkText} cancelText={this.state.modalCancelText} onClose={() => { this.setState({ showModalReservations: false }) }}>
                    <ReservationHistory buildingId={this.state.selectedBuildingId} />
                </SimpleModal>
                <h4>
                    <span className="mr-2">Filiales</span>
                    <button className="btn btn-sm btn-success" onClick={this.openModalNew}><span className="fa fa-plus"></span></button>
                </h4>
                <DataTable className="wrap"
                    columns={this.state.columns}
                    data={this.state.buildingList}
                    pagination={true}
                />
            </div>
        );
    }
}

export default AdminBuildings;