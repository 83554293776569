import React from 'react';
import { Modal, Button } from 'react-bootstrap';

class SimpleModal extends React.Component {
    closeModal = () => {
        if (this.props.onClose) { this.props.onClose();}
    }

    okClick = () => {
        this.closeModal();
        if (this.props.okClick) { this.props.okClick(); }
    }

    cancelClick = () => {
        this.closeModal();
        if (this.props.cancelClick) { this.props.cancelClick();}
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.closeModal} dialogClassName={this.props.dialogClassName}>
                <Modal.Body>
                    {this.props.body}
                    {this.props.children}
                </Modal.Body>
                <Modal.Footer>
                    {this.props.cancelText &&
                        <Button variant="secondary" onClick={this.cancelClick}>
                            {this.props.cancelText}
                        </Button>
                    }
                    {this.props.okText &&
                        <Button variant="primary" onClick={this.okClick}>
                            {this.props.okText}
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
            );
    }
}

export default SimpleModal;