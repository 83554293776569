import React from 'react';
import Storage from '../common/Storage';
import axios from 'axios';
import Constants from '../common/Constants';
import SimpleModal from '../common/SimpleModal';
import DataTable from 'react-data-table-component';
import AdminReportItem from './AdminReportItem';

class AdminReports extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentReport: {},
            showModalReport: false,
            reportList: [],
            columns: [
                {
                    name: 'Tipo', selector: 'reportTypeRName', sortable: true
                },
                {   name: 'Nombre', selector: 'name', sortable: true },
                {   name: 'Descripcion', selector: 'description', sortable: true, right: true },
                {   name: 'Creado', selector: 'createdDate', sortable: true, right: true },
                {
                    name: 'Estado', selector: 'reportStatus', sortable: true, right: true, cell: row => (
                        <span>{(row.reportStatus == 1 ? "Publicado" : "Borrador")}</span>
                    )
                },
                {
                    name: 'Acciones', right: true, cell: row => (
                        <div>
                            <a target="_blank" href={"/assets/appfiles/reports/" + row.name} className="btn btn-sm btn-info"><span className="fa fa-file-pdf-o"></span></a>
                            <button className="btn btn-sm btn-warning" onClick={() => { this.ShowModalReport(row); }}><span className="fa fa-pencil"></span></button>
                            <button className="btn btn-sm btn-danger" onClick={() => { this.ShowModalDelete(row);}}><span className="fa fa-trash"></span></button>
                        </div>
                    )
                },
            ],
        };
    }

    ShowModalDelete = (r) => {
        let currentReport = r;
        this.setState({ deleteModalShow: true, currentReport});
    }

    ShowModalReport = (r) => {
        let currentReport = r;
        this.setState({ showModalReport: true, currentReport, action: Constants.STATUS_EDIT });
    }

    DoDelete = () => {
        axios.delete(Constants.URL_API + 'Report/Delete?id=' + this.state.currentReport.reportId, { headers: Storage.getAuth() })
            .then(res => {
                this.UpdateReports();
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
    }

    UpdateReports = () => {
        axios.get(Constants.URL_API + 'Report/GetAll?Id=' + this.props.residentialId, { headers: Storage.getAuth() })
            .then(res => {
                let data = res.data.map(i => {
                    i.createdDate = i.createdDate.substring(i.createdDate, 10);
                    i.reportTypeRName = (i.reportTypeR ? i.reportTypeR.name : "");
                    return i;
                });
                this.setState({ reportList: data });
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
    }

    modalOkClick = () => {
        this.SaveReport();
    }

    OnReportSaved = () => {
        this.setState({ savedModalShow: true });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.residentialId == this.props.residentialId) { return; }

        this.UpdateReports();
    }

    openModalNew = () => {
        this.setState({
            currentReport: {
                name: "",
                residentialId: this.props.residentialId,
                reportType: 0
            },
            showModalReport: true,
            action: Constants.STATUS_NEW
        });
    }

    render() {
        return (
            <div>
                <h4>
                    <span className="mr-2">Informes</span>
                    <button className="btn btn-sm btn-success" onClick={this.openModalNew}><span className="fa fa-plus"></span></button>
                </h4>
                <SimpleModal body="Los cambios se han guardado correctamente" okText="Gracias!" show={this.state.savedModalShow} onClose={() => { this.setState({ savedModalShow: false }); this.UpdateReports(); }} />
                <SimpleModal body="Esta seguro que desea eliminar este elemento?" okClick={this.DoDelete} okText="Si, borrar" cancelText="No borrar" show={this.state.deleteModalShow} onClose={() => { this.setState({ deleteModalShow: false }); this.UpdateReports(); }} />
                <SimpleModal action={this.state.action} show={this.state.showModalReport} okClick={this.modalOkClick} okText="Guardar" cancelText="Cancelar" onClose={() => { this.setState({ showModalReport: false }) }}>
                    <AdminReportItem
                        report={this.state.currentReport}
                        action={this.state.action}
                        setSave={s => this.SaveReport = s}
                        onReportSaved={this.OnReportSaved}
                        abbreviation={this.props.abbreviation}
                    />
                </SimpleModal>
                <DataTable
                    columns={this.state.columns}
                    data={this.state.reportList}
                    pagination={true}
                />
            </div>
        );
    }
}

export default AdminReports;