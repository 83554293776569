import React from 'react';
import Storage from '../common/Storage';
import axios from 'axios';
import Constants from '../common/Constants';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';

class News extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            newsList: [],
            openList: [],
        };
    }

    componentDidMount() {
        let user = Storage.getItem('CurrentUser');
        if (!user.building) { return;}

        axios.get(Constants.URL_API + 'News/GetAll?Id=' + user.building.residential.residentialId + "&NewsType="+this.props.newsType, { headers: Storage.getAuth() })
            .then(res => {
                let data = res.data.map(i => {
                    i.createdDate = i.createdDate.substring(i.createdDate, 10);
                    i.modifiedDate = i.modifiedDate.substring(i.modifiedDate, 10);
                    i.content = i.content.replace("<img ", "<img style='max-width: 250px;'");
                    return i;
                });
                this.setState({ newsList: data });
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
    }

    render() {
        return (
            <div className="d-flex flex-column" id="content-wrapper" >
                <div id="content">
                    <div className="container-fluid">
                        <h3 className="text-dark mb-1">{this.props.newsType == 0 ? "Noticias" : "Recordatorios"}</h3>
                        <hr />

                        <div class="row">
                            {this.state.newsList.length == 0 && 
                                <p>Para este condominio a&uacute;n no se han registrado elementos.</p>
                            }
                            {
                                this.state.newsList.sort((n1, n2) => (n1.modifiedDate > n2.modifiedDate ? -1 : 1)).map((i,index) =>
                                    <div className="col-sm-6">
                                        <div className="card" >
                                            <div className="card-body">
                                                <div><h5 className="card-title">{i.title} - <em style={{ fontSize: "0.7em" }}>actualizado el {i.modifiedDate}</em></h5></div>
                                                <div className="card-text" dangerouslySetInnerHTML={{ __html: i.content }} style={{ textAlign: "justify", margin: "10px" }}></div>
                                            </div>
                                        </div>
                                        <br />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default News