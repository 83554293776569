let base = {
	STATUS_NEW: "NEW",
	STATUS_EDIT: "EDIT",
	STATUS_DELETE: "DELETE"
}

const prod = {
	//URL_API: "/api/"
	URL_API: window.location.protocol + "//" + window.location.hostname + ":5001/api/"
	
};

const dev = {
	URL_API: "http://localhost:60462/api/"
	//URL_API: window.location.protocol + "//" + window.location.hostname + ":5002/api/" 
};

let Constants = Object.assign(base, process.env.NODE_ENV === 'development' ? dev : prod) ;
export default Constants;