import React from 'react';
import Storage from '../common/Storage';

class Rule extends React.Component {
    constructor(props) {
        super(props);

        let user = Storage.getItem('CurrentUser');
        let reportList = [
            { residentialId: 1, file: "Reglamento01.pdf", detail: "Reglamento Residencial Antares" },
            { residentialId: 2, file: "Reglamento02.pdf", detail: "Reglamento Residencial Brisas" },
            { residentialId: 3, file: "Reglamento03.pdf", detail: "Reglamento Residencial Granadilla" },
            { residentialId: 4, file: "Reglamento04.pdf", detail: "Reglamento Residencial Cipreses" },
            { residentialId: 5, file: "Reglamento05.pdf", detail: "Reglamento Condo Pruebas" },
            { residentialId: 6, file: "Reglamento06.pdf", detail: "Reglamento Residencial Las Loras" },
            { residentialId: 8, file: "Reglamento08.pdf", detail: "Reglamento Residencial Vereda" },
            { residentialId: 9, file: "Reglamento09.pdf", detail: "Reglamento Residencial Alamo" },
            { residentialId: 10, file: "Reglamento10.pdf", detail: "Reglamento Condominio Guayabos" }
        ].filter((r) => r.residentialId == user.building.residential.residentialId);

        this.state = {
            user,
            reportList
        };
    }

    render() {
        return (
            <div className="d-flex flex-column" id="content-wrapper" >
                <div id="content">
                    <div className="container-fluid">
                        <h3 className="text-dark mb-1">Reglamento</h3>
                        <div>
                            <hr />
                        </div>
                        <div className="row m-2 d-flex flex-column justify-content">
                            {this.state.reportList.map(r =>
                                <div class="alert alert-warning" role="alert">
                                    <a target="_blank" href={"/assets/appfiles/rules/" + r.file}>{r.detail}</a>
                                </div>
                            )
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Rule