import React from 'react';
import Storage from '../common/Storage';
import axios from 'axios';
import Constants from '../common/Constants';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';

class Report extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            reportList: [],
            openList: []
        };
    }

    componentDidMount() {
        let user = Storage.getItem('CurrentUser');
        if (!user.building) { return;}

        axios.get(Constants.URL_API + 'Report/GetAll?Id=' + user.building.residential.residentialId, { headers: Storage.getAuth() })
            .then(res => {
                let data = res.data.map(i => {
                    i.createdDate = i.createdDate.substring(i.createdDate, 10);
                    return i;
                });
                this.setState({ reportList: data });
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
    }

    render() {
        let list = this.state.reportList.map(r => (r.reportTypeR ? r.reportTypeR.reportTypeId : null));
        list = list.filter((value, index, self) => self.indexOf(value) === index);

        return (
            <div className="d-flex flex-column" id="content-wrapper" >
                <div id="content">
                    <div className="container-fluid">
                        <h3 className="text-dark mb-1">Informes</h3>
                        <div>
                            <hr />
                        </div>

                        <div className="row">
                            {
                                list.map((i, index) =>
                                    <div className="col-sm-4">
                                        <div className="card" >
                                            <div className="card-body" >
                                                <Button
                                                    onClick={() => {
                                                        let openList = this.state.openList;
                                                        openList[index] = !openList[index];
                                                        this.setState({ openList })
                                                    }
                                                    }
                                                >
                                                    <h5 className="card-title">{(i ? this.state.reportList.filter(r => r.reportTypeR && i == r.reportTypeR.reportTypeId)[0].reportTypeR.name : "Otros")}</h5>
                                                </Button>
                                                <Collapse in={this.state.openList[index]}>
                                                    <ul className="list-group list-group-flush">
                                                        {
                                                            this.state.reportList.filter(r => i == (r.reportTypeR ? r.reportTypeR.reportTypeId : null)).map(r =>
                                                                <li className="list-group-item">
                                                                    <a target="_blank" href={"/assets/appfiles/reports/" + r.name}>{r.description}</a>
                                                                </li>
                                                            )
                                                        }
                                                    </ul>
                                                </Collapse>
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                    
                                    )
                            }
                        
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Report