import React from 'react';
import { Link } from 'react-router-dom';
import Storage from '../common/Storage';

class Home extends React.Component {
    constructor(props) {
        super(props);

        let user = Storage.getItem('CurrentUser');
        this.state = {
            user
        };
    }

    render() {
        return (
            <div className="d-flex flex-column" id="content-wrapper">
                <div id="content">
                    <div className="container-fluid">
                        <h3 className="text-dark mb-1">Por favor elija una opcion</h3>
                        <div>
                            <hr />
                        </div>
                        <div className="row justify-content-center features">
                            <div className="col-sm-6 col-md-5 col-lg-4 item">
                                <div className="box">
                                    <h3 className="name"><Link to="/profile" className="text-secondary"><i className="fa fa-user icon" style={{ fontSize: '30px' }}></i>&nbsp;Perfil</Link></h3>
                                    <p className="description">Administre su perfil como nombre, correo electronico y otros miembros de su habitacion.</p>
                                </div>
                            </div>

                            <div className="col-sm-6 col-md-5 col-lg-4 item">
                                <div className="box">
                                    <h3 className="name"><Link to="/reminder" className="text-secondary"><i className="fa fa-exclamation-triangle icon" style={{ fontSize: '30px' }}></i>&nbsp;Recordatorios</Link></h3>
                                    <p className="description">Amables recordatorios para una convivencia pacifica.</p>
                                </div>
                            </div>

                            <div className="col-sm-6 col-md-5 col-lg-4 item">
                                <div className="box">
                                    <h3 className="name"><Link to="/rule" className="text-secondary"><i className="fa fa-balance-scale icon" style={{ fontSize: '30px' }}></i>&nbsp;Reglamento</Link></h3>
                                    <p className="description">Lea el reglamento del condominio y adhierase a el para una mejor convivencia.</p>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-5 col-lg-4 item">
                                <div className="box">
                                    <h3 className="name"><Link to="/reserve" className="text-secondary"><i className="fa fa-calendar-check-o icon" style={{ fontSize: '30px' }}></i>&nbsp;Reserva de instalaciones</Link></h3>
                                    <p className="description">Separe con antelacion las instalaciones para su familia e invitados.</p>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-5 col-lg-4 item">
                                <div className="box">
                                    <h3 className="name"><Link to="/news" className="text-secondary"><i className="fa fa-newspaper-o icon" style={{ fontSize: '30px' }}></i>&nbsp;Noticias</Link></h3>
                                    <p className="description">Mantengase al dia con las noticias mas recientes del condominio.</p>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-5 col-lg-4 item">
                                <div className="box">
                                    <h3 className="name"><Link to="/report" className="text-secondary"><i className="fa fa-file-text icon" style={{ fontSize: '30px' }}></i>&nbsp;Informes</Link></h3>
                                    <p className="description">Lea el informe mas reciente por parte del administrador del condominio.</p>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-5 col-lg-4 item">
                                <div className="box">
                                    <h3 className="name"><Link to="/visit" className="text-secondary"><i className="fa fa-users icon" style={{ fontSize: '30px' }}></i>&nbsp;Visitas</Link></h3>
                                    <p className="description">Reporte sus visitas por adelantado al departamento de seguridad del condominio.</p>
                                </div>
                            </div>
                            {this.state.user.userType == 3 &&
                                <div className="col-sm-6 col-md-5 col-lg-4 item">
                                    <div className="box">
                                        <h3 className="name"><Link to="/equipmentLoan" className="text-secondary"><i className="fa fa-id-badge icon" style={{ fontSize: '30px' }}></i>&nbsp;Equipo</Link></h3>
                                        <p className="description">Prestamo de equipo. Solo personal autorizado.</p>
                                    </div>
                                </div>
                            }
                            {this.state.user.userType < 2 &&
                                <div className="col-sm-6 col-md-5 col-lg-4 item">
                                    <div className="box">
                                    <h3 className="name"><Link to="/admin" className="text-secondary"><i className="fa fa-cog icon" style={{ fontSize: '30px' }}></i>&nbsp;Administracion</Link></h3>
                                        <p className="description">Administracion de residenciales, usuarios y comunicaciones. Solo personal autorizado.</p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home