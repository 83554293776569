import React from 'react';
import Storage from '../common/Storage';
import axios from 'axios';
import Constants from '../common/Constants';
import TimePicker from 'react-time-picker';
import Moment from 'moment';

class AdminReserveItem extends React.Component {
    constructor(props) {
        super(props);

        let user = Storage.getItem('CurrentUser');

        let reservationBundle = this.props.reservationBundle;
        this.state = {
            user,
            reservationBundle,
            timeStart: JSON.stringify(reservationBundle.reservation.TimeStart).substr(12, 8),
            timeEnd: JSON.stringify(reservationBundle.reservation.TimeEnd).substr(12, 8)
        };
    }

    componentDidMount() {
        this.props.setSave(this.Save);
    }

    Save = () => {
        let date = JSON.stringify(this.props.reservationBundle.reservation.TimeStart).substr(1, 11);
        let reservation = this.state.reservationBundle.reservation;
        let data = Object.assign(reservation, {TimeStart: date+this.state.timeStart, TimeEnd: date+this.state.timeEnd});

        axios.post(Constants.URL_API + 'reservation/New', data, { headers: Storage.getAuth() })
            .then(res => {
                if (this.props.resultCallback) { this.props.resultCallback(res.status, res.data); }
            })
            .catch((error) => {
                console.log(error);
                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
                if (this.props.resultCallback) { this.props.resultCallback(this.state.apiCode, 0); }
            });
    }

    doReserve = () => {
        let data = this.state.reservationBundle.reservation
        console.log(data); return;
    }

    TimeStartChanged = (time) => { this.setState({ timeStart: time}); }
    TimeEndChanged = (time) => { this.setState({ timeEnd: time }); }

    render() {
        return (
            <>
                <>
                    <p>Esta seguro de guardar esta reservacion?</p>
                    <p>Filial: {this.state.reservationBundle.selectedBuilding.code}</p>
                    <p>Recurso: {this.state.reservationBundle.selectedResource.name}</p>
                </>

                {this.state.user.userType <= 1 || this.state.reservationBundle.selectedResource.periods === 2 ?
                    <>
                        <div className="row">
                            <div className="col-sm-4">Inicio: </div>
                            <div className="col-sm-8">
                                <TimePicker className="form-control" onChange={this.TimeStartChanged} value={this.state.timeStart} clockIcon={null} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4">Fin: </div>
                            <div className="col-sm-8">
                                <TimePicker className="form-control" onChange={this.TimeEndChanged} value={this.state.timeEnd} clockIcon={null} />
                            </div>
                        </div>
                    </>
                    :
                    <p>Hora: {JSON.stringify(this.state.reservationBundle.reservation.TimeStart).substr(12, 8) }</p>
                    
                }

                {false && <button onClick={this.Save}>Save</button> }
            </>
        );
    }
}

export default AdminReserveItem;
