import React from 'react';
import Login from './components/login/Login';
import Home from './components/home/Home';
import Soon from './components/soon/Soon';
import Profile from './components/profile/Profile';
import Reserve from './components/reserve/Reserve';
import Report from './components/report/Report';
import Rule from './components/rule/Rule';
import Visit from './components/visit/Visit';
import Menu from './components/common/Menu';
import Admin from './components/admin/Admin';
import Support from './components/support/Support';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Storage from './components/common/Storage';
import News from './components/news/News';
import AdminEquipmentLoans from './components/admin/AdminEquipmentLoans';
import ReservationHistory from './components/reserve/ReservationHistory';
import Reminder from './components/reminder/Reminder';
import AdminResourceLock from './components/admin/AdminResourceLock';

import "rc-tree/assets/index.css";
import Tree, { TreeNode } from 'rc-tree';

function Routes() {
    let user = Storage.getItem('CurrentUser');

    if (window.location.href.endsWith("support")) {
        return (
            <Support />
        );
    }

    if (!user) {
        return (
            <Login />
        );
    }
    else
    return (
        <Router>
            <Switch>
                <Route exact path="/testy">
                    <Tree draggable={true} style={{ textAlign: "left" }}>
                        <TreeNode title="2020">
                            <TreeNode title="Otros">
                                <TreeNode title="Informe Mayo 2020" ></TreeNode>
                            </TreeNode>
                            <TreeNode title="Informe Financiero" >
                                <TreeNode title="Enero"></TreeNode>
                                <TreeNode title="Febrero"></TreeNode>
                                <TreeNode title="Marzo"></TreeNode>
                                <TreeNode title="..."></TreeNode>
                            </TreeNode>
                            <TreeNode title="Reporte de equipos" >
                                <TreeNode title="Reporte de aguas residuales" ></TreeNode>
                                <TreeNode title="Reporte Agua Residuales Enero 2021" ></TreeNode>
                            </TreeNode>
                        </TreeNode>
                        <TreeNode title="2021">
                            <TreeNode title="Otros">
                                <TreeNode title="Informe Mayo 2020" ></TreeNode>
                            </TreeNode>
                            <TreeNode title="Informe Financiero" >
                                <TreeNode title="Enero"></TreeNode>
                                <TreeNode title="Febrero"></TreeNode>
                                <TreeNode title="Marzo"></TreeNode>
                                <TreeNode title="..."></TreeNode>
                            </TreeNode>
                            <TreeNode title="Reporte de equipos" >
                                <TreeNode title="Reporte de aguas residuales" ></TreeNode>
                                <TreeNode title="Reporte Agua Residuales Enero 2021" ></TreeNode>
                            </TreeNode>
                        </TreeNode>
                        <TreeNode title="2022">
                            <TreeNode title="Otros">
                                <TreeNode title="Informe Mayo 2020" ></TreeNode>
                            </TreeNode>
                            <TreeNode title="Informe Financiero" >
                                <TreeNode title="Enero"></TreeNode>
                                <TreeNode title="Febrero"></TreeNode>
                                <TreeNode title="Marzo"></TreeNode>
                                <TreeNode title="..."></TreeNode>
                            </TreeNode>
                            <TreeNode title="Reporte de equipos" >
                                <TreeNode title="Reporte de aguas residuales" ></TreeNode>
                                <TreeNode title="Reporte Agua Residuales Enero 2021" ></TreeNode>
                            </TreeNode>
                        </TreeNode>


                        
                    </Tree>
                </Route>
                <Route exact path="/test">
                    <div id="wrapper" className="bg-light">
                        {/*
                        <Menu />
                        <AdminResourceLock resource={
                            {
                                "resourceId": 1, "name": "Casa Club", "residential": { "residentialId": 1, "name": "Antares", "isActive": true, "email": null, "rulesFile": "ReglamentoAntares.pdf" }, "periods": 0, "maxLockMinutes": 180, "isActive": true, "restriction_TimeUnit": 3, "restriction_TimeUnitGroup": 1, "restriction_MaxQuantity": 1, "restriction_FutureReservationsLimit": 3, "restriction_AllowedReservationPeriod": 4, "restriction_LimitedWeekdaysFlags": 0, "restriction_LimitedWeekdaysLimit": 0,
                                "locks": [
                                    { "resourceLockId": 1, "timeStart": "2020-01-01T00:00:00", "timeEnd": "2020-01-01T05:00:00", "dayOfWeek": 31, "createdBy": { "userId": 1, "name": "Carmen Boza", "email": "carmen.boza@hotmail.com", "building": { "buildingId": 2, "code": "02", "residential": { "residentialId": 1, "name": "Antares", "isActive": true, "email": null, "rulesFile": "ReglamentoAntares.pdf" }, "isActive": true, "authorizedGuests": "Autorizado 02 C\nEmpleada domestica Maria", "owners": null }, "userType": 2, "createdDate": "2020-06-17T08:01:53", "isActive": true, "token": null, "pwHash": null }, "createdDate": "2023-01-16T00:00:00" },
                                    { "resourceLockId": 2, "timeStart": "2020-01-01T20:00:00", "timeEnd": "2020-01-01T23:59:59", "dayOfWeek": 30, "createdBy": { "userId": 1, "name": "Carmen Boza", "email": "carmen.boza@hotmail.com", "building": { "buildingId": 2, "code": "02", "residential": { "residentialId": 1, "name": "Antares", "isActive": true, "email": null, "rulesFile": "ReglamentoAntares.pdf" }, "isActive": true, "authorizedGuests": "Autorizado 02 C\nEmpleada domestica Maria", "owners": null }, "userType": 2, "createdDate": "2020-06-17T08:01:53", "isActive": true, "token": null, "pwHash": null }, "createdDate": "2023-01-16T00:00:00" }
                                ]
                            }
                        } />
                        */}
                    </div>
                </Route>
                <Route exact path="/support">
                    <div id="wrapper" className="bg-light">
                        <Support />
                    </div>
                </Route>                
                <Route exact path="/profile">
                    <div id="wrapper" className="bg-light">
                        <Menu />
                        <Profile />
                    </div>
                </Route>
                <Route exact path="/reminder">
                    <div id="wrapper" className="bg-light">
                        <Menu />
                        {false &&
                            <Reminder residentialId={user.building ? user.building.residential.residentialId : 0} />
                        }
                        <News newsType="1" />
                    </div>
                </Route>
                <Route exact path="/soon">
                    <div id="wrapper" className="bg-light">
                        <Menu />
                        <Soon />
                    </div>
                </Route>
                <Route exact path="/reserve">
                    <div id="wrapper" className="bg-light">
                        <Menu />
                        <Reserve />
                    </div>
                </Route>
                <Route exact path="/myreservations">
                    <div id="wrapper" className="bg-light">
                        <Menu />
                        <ReservationHistory />
                    </div>
                </Route>
                <Route exact path="/rule">
                    <div id="wrapper" className="bg-light">
                        <Menu />
                        <Rule />
                    </div>
                </Route>
                <Route exact path="/report">
                    <div id="wrapper" className="bg-light">
                        <Menu />
                        <Report />
                    </div>
                </Route>
                <Route exact path="/homeMenu">
                    <div id="wrapper" className="bg-light">
                        <Home />
                    </div>
                </Route>
                <Route exact path="/visit">
                    <div id="wrapper" className="bg-light">
                        <Menu /> 
                        <Visit />
                    </div>
                </Route>
                <Route exact path="/news">
                    <div id="wrapper" className="bg-light">
                        <Menu />
                        <News newsType="0" />
                    </div>
                </Route>
                <Route exact path={["/home", "/login", "/"]}>
                    <div id="wrapper" >
                        <Menu />
                        <Home />
                    </div>
                </Route>
                <Route exact path="/equipmentLoan">
                    <div id="wrapper" className="bg-light">
                        <Menu />
                        <AdminEquipmentLoans residentialId={user.building ? user.building.residential.residentialId : 0}/>
                    </div>
                </Route>
                <Route exact path="/admin">
                    <div id="wrapper" className="bg-light">
                        <Menu />
                        <Admin />
                    </div>
                </Route>
            </Switch>
        </Router>
    );
}
export default Routes