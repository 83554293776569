import React from 'react';
import Storage from '../common/Storage';
import axios from 'axios';
import Constants from '../common/Constants';
import AdminUsers from './AdminUsers';

class AdminEquipmentLoanItem extends React.Component {
    constructor(props) {
        super(props);

        let equipmentLoan = this.props.equipmentLoan;
        this.state = {
            equipmentLoan,
        };
    }
    
    componentDidMount() {
        this.props.setSave(this.Save);
    } 

    Save = () => {
        if (this.props.action == Constants.STATUS_EDIT) { this.Update(); }
        if (this.props.action == Constants.STATUS_NEW) { this.Create(); }
    }
    
    Update = () => {
        this.SaveAPI("Update?id=" + this.state.equipmentLoan.equipmentLoanId,"put",this.state.equipmentLoan);
    }
    Create = () => {
        this.SaveAPI("New", "post", this.state.equipmentLoan);
    }
    
    SaveAPI = (action, method, data) => {
        axios(Constants.URL_API + 'EquipmentLoan/' + action, { method, data, headers: Storage.getAuth() })
            .then(res => {
                if (this.props.onEquipmentLoanSaved) { this.props.onEquipmentLoanSaved(); }
            })
            .catch((error) => {
                if (error.response) {
                    alert("No se guardo el elemento; revise todos los campos e intente nuevamente.");
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
    }

    EquipmentTypeChanged = (e) => {
        let equipmentLoan = this.state.equipmentLoan;
        equipmentLoan.equipmentType = parseInt(e.target.value);
        this.setState({ equipmentLoan, rowStatus: Constants.STATUS_EDIT });
    }

    NameChanged = (e) => {
        let equipmentLoan = this.state.equipmentLoan;
        equipmentLoan.name = e.target.value;
        this.setState({ equipmentLoan, rowStatus: Constants.STATUS_EDIT });
    }

    RecipientChanged = (e) => {
        let equipmentLoan = this.state.equipmentLoan;
        equipmentLoan.recipient = e.target.value;
        this.setState({ equipmentLoan, rowStatus: Constants.STATUS_EDIT });
    }

    DetailsChanged = (e) => {
        let equipmentLoan = this.state.equipmentLoan;
        equipmentLoan.details = e.target.value;
        this.setState({ equipmentLoan, rowStatus: Constants.STATUS_EDIT });
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col-sm-3">Tipo: </div>
                    <div className="col-sm-9">
                        <select className="form-control" defaultValue={this.state.equipmentLoan.equipmentType ? this.state.equipmentLoan.equipmentType : 0} onChange={(e) => this.EquipmentTypeChanged(e)} readOnly={this.props.action == Constants.STATUS_EDIT}>
                            <option value="0">Otro</option>
                            <option value="1">Gafete</option>
                            <option value="2">Tarjeta</option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-3">Identificador: </div>
                    <div className="col-sm-9">
                        <input type="text" className="form-control" value={this.state.equipmentLoan.name} onChange={this.NameChanged} readOnly={this.props.action == Constants.STATUS_EDIT} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-3">Prestado a: </div>
                    <div className="col-sm-9">
                        <input type="text" className="form-control" value={this.state.equipmentLoan.recipient} onChange={this.RecipientChanged} readOnly={this.props.action == Constants.STATUS_EDIT} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-3">Detalles: </div>
                    <div className="col-sm-9">
                        <textarea className="form-control" value={this.state.equipmentLoan.details} onChange={this.DetailsChanged} rows="6" readOnly={this.props.action == Constants.STATUS_EDIT} />
                    </div>
                </div>
            </>
        );
    }
}

export default AdminEquipmentLoanItem;