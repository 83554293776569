import React from 'react';
import Storage from '../common/Storage';
import Constants from '../common/Constants';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';

class ReservationHistory extends React.Component {
    constructor(props) {
        super(props);

        let user = Storage.getItem('CurrentUser');
        this.state = {
            user,
        };
    }
    /*
     
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.residentialId == this.props.residentialId) { return; }
        this.UpdateBuildings();
    }
    */
    componentDidMount() {
        let buildingId = (this.props.buildingId ? this.props.buildingId :
            (this.state.user.building ? this.state.user.building.buildingId : 0)
        );

        if (buildingId > 0) { this.refreshReservations(buildingId, "GetByBuildingId"); }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.residentialId == this.props.residentialId) { return; }

        let residentialId = (this.props.residentialId ? this.props.residentialId :
            (this.state.user.building ? this.state.user.building.buildingId : 0)
        );
        if (residentialId > 0) { this.refreshReservations(residentialId, "GetAll");}
        
        let buildingId = (this.props.buildingId ? this.props.buildingId :
            (this.state.user.building ? this.state.user.building.buildingId : 0)
            );

        if (buildingId > 0) { this.refreshReservations(buildingId, "GetByBuildingId"); }
    }

    saveFlags = (id, flags) => {
        axios(Constants.URL_API + 'reservation/Flags?id=' + id + '&flags=' + flags, { method: "put", data: null , headers: Storage.getAuth() })
        //axios.put(Constants.URL_API + 'reservation/Flags?id=' + id+'&flags='+flags, { headers: Storage.getAuth() })
            .then(res => {
                
            })
            .catch((error) => {
                alert("No se pudo completar la accion; por favor intente nuevamente.");
                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
    }

    refreshReservations = (id, method) => {
        axios.get(Constants.URL_API + 'reservation/'+method+'?id=' + id, { headers: Storage.getAuth() })
            .then(res => {
                res.data.map(r => {
                    r.title = "#" + r.building.code + ' - ' + r.resource.name;
                    r.start = r.timeStart;
                    r.end = r.timeEnd;
                    r.className = 'resource_' + r.resource.resourceId;
                    return r;
                });
                this.setState({ calendarEvents: res.data });
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
    }

    SearchChanged = (e) => {
        let search = e.target.value;
        this.setState({ search });
    }

    OnRowFlagClick = (id,flagValue) => {
        var calendarEvents = this.state.calendarEvents;
        let reservation = calendarEvents.find(e => e.reservationId == id);
        Object.assign(reservation, { flags: reservation.flags ^ flagValue });
        this.setState(calendarEvents);
        this.saveFlags(reservation.reservationId, reservation.flags);
        //console.log(calendarEvents);
    }

    render() {
        return (
            <>
                <div id="content">
                    <div className="container-fluid">
                        <div className="row justify-content-start">
                            <div className="col-3 center">
                                {this.state.user.building && <Link to="/reserve"><span className="fa fa-calendar"></span> Volver a Reservaciones</Link>
                                }
                            </div>
                            <div className="col-4"><h3 className="text-dark mb-1">Mis Reservaciones</h3></div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-8">&nbsp;</div>
                    <div className="col-4">
                        <div className="input-group rounded">
                            <input type="search" className="form-control rounded" placeholder="Buscar..." aria-label="Buscar..." aria-describedby="search-addon" onChange={this.SearchChanged} />
                            <span className="input-group-text border-0" id="search-addon">
                                <i className="fas fa-search"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <DataTable
                    columns={
                        [
                            { name: 'Filial', selector: 'building.code', sortable: true },
                            { name: 'Recurso', selector: 'resource.name', sortable: true },
                            {
                                name: 'Fecha', selector: 'timeStart', sortable: true, cell: row => (
                                    <Moment format="DD/MM/YYYY hh:mm A" add={{ hours: 0 }}>
                                        {row.timeStart}
                                    </Moment>
                                )
                            },
                            { name: 'Creado por', selector: 'createdBy.name', sortable: true },
                            {
                                name: 'Creado el', selector: 'createdDate', sortable: true, cell: row => (
                                    <Moment format="DD/MM/YYYY hh:mm A" add={{ hours: 0 }}>
                                        {row.createdDate}
                                    </Moment>
                                )
                            },
                            {
                                name: 'Acciones', selector: 'flags', sortable: true, cell: row => (
                                    <>
                                        <button className={"btn btn-sm btn-" + (row.flags & 1 > 0 ? "success" : "information")} onClick={() => this.OnRowFlagClick(row.reservationId, 1)}><span className="fa fa-money"></span></button>
                                    </>
                                )
                            },
                        ]
                    }

                    data={this.state.calendarEvents ? this.state.calendarEvents.filter(i =>
                        !this.state.search ||
                        i.building.code.toLowerCase().includes(this.state.search.toLowerCase()) ||
                        i.resource.name.toLowerCase().includes(this.state.search.toLowerCase()) ||
                        i.timeStart.toLowerCase().includes(this.state.search.toLowerCase()) ||
                        i.createdBy.name.toLowerCase().includes(this.state.search.toLowerCase())
                    ) : []}
                    
                    pagination={true}
                />
                { /* data={this.state.calendarEvents} */ }
            </>
        )
    }
}

export default ReservationHistory;