import React from 'react';
import Storage from '../common/Storage';
import axios from 'axios';
import Constants from '../common/Constants';
import SimpleModal from '../common/SimpleModal';

class AdminUsers extends React.Component {
    constructor(props) {
        super(props);

        let user = Storage.getItem('CurrentUser');

        this.state = {
            user,
            userList: [],
            buildingList: [],
            newId: 0,
            savedModalShow: false
        };
    }

    
    loadUsers = () => {
        let action = "";
        if (this.props.buildingId) { action = "ByBuilding?id=" + this.props.buildingId; }
        if (this.props.residentialId) { action = "ByResidential?id=" + this.props.residentialId; }
        if (action == "") { return; }

        axios.get(Constants.URL_API + 'User/' + action, { headers: Storage.getAuth() })
            .then(res => {
                let userList = res.data;
                userList.sort((a, b) => (a.building.code > b.building.code ? 1 : -1));
                this.setState({ userList });
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
    }

    loadBuildings = () => {
        if (this.state.user.userType > 1) {
            let buildingList = [this.state.user.building];
            this.setState({ buildingList });
            return;
        }

        let action = "";
        if (this.props.buildingId) { action = "GetById?id=" + this.props.buildingId; }
        if (this.props.residentialId) { action = "GetAll?id=" + this.props.residentialId; }
        if (action == "") { return;}

        axios.get(Constants.URL_API + 'Building/' + action, { headers: Storage.getAuth() })
            .then(res => {
                let buildingList = res.data;
                buildingList.sort((a, b) => (a.code > b.code ? 1 : -1));
                this.setState({ buildingList });
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
    }

    reloadData = () => {
        this.loadBuildings();
        this.loadUsers();
    }

    componentDidMount() {
        this.reloadData();
        if (this.props.setSave) { this.props.setSave(this.Save); }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.buildingId == this.props.buildingId && prevProps.residentialId == this.props.residentialId) { return; }

        this.reloadData();
    }

    UserDelete = (r) => {
        this.UserPropertyUpdate(r, { rowStatus: Constants.STATUS_DELETE });
    }

    UserAdd = () => {
        let userList = this.state.userList;
        let newId = this.state.newId - 1;
        let building = this.state.buildingList[0];

        userList.unshift({ userId: newId, name: "", email: "", userType: 2, building, rowStatus: Constants.STATUS_NEW });
        this.setState({ userList, newId });
    }

    UserPropertyUpdate = (r, newObj) => {
        let userList = this.state.userList;
        let user = userList.find((i) => i.userId === r.userId);
        Object.assign(user, { rowStatus: Constants.STATUS_EDIT }, newObj );
        this.setState(userList);
    }

    UserChangeName = (r, e) => {
        this.UserPropertyUpdate(r, { name: e.target.value });
    }

    UserChangeEmail = (r, e) => {
        this.UserPropertyUpdate(r, { email: e.target.value });
    }

    UserChangeUserType = (r, e) => {
        this.UserPropertyUpdate(r, { userType: parseInt(e.target.value) });
    }

    UserChangeBuilding = (r, e) => {
        let building = this.state.buildingList.find((i) => i.buildingId == e.target.value);
        this.UserPropertyUpdate(r, { building });
    }

    UserClearPassword = (r) => {
        this.UserPropertyUpdate(r, { pwHash: "" });
    }

    Save = () => {
        this.Create();
        this.Update();
        this.Delete();
        this.setState({ savedModalShow: true});
    }

    CleanStatus = () => {
        let userList = this.state.userList; 
        userList.forEach(u => u.rowStatus = null);
        this.setState({userList});
    }

    Create = () => {
        let list = this.state.userList.filter(u => u.userId < 0 && u.rowStatus == Constants.STATUS_EDIT);
        this.SaveAPI("CreateMultiple", "post", list);
    }

    Update = () => {
        let list = this.state.userList.filter(u => u.userId > 0 && u.rowStatus == Constants.STATUS_EDIT);
        this.SaveAPI("UpdateMultiple", "put", list);
    }

    Delete = () => {
        let list = this.state.userList.filter(u => u.userId > 0 && u.rowStatus == Constants.STATUS_DELETE);
        let ids = list.map(u => u.userId);
        this.SaveAPI("DeleteMultiple", "delete", ids);
    }

    SaveAPI = (action, method, data) => {
        if (data.length == 0) { return; }
        axios(Constants.URL_API + 'User/' + action, { method, data, headers: Storage.getAuth() })
            .then(res => {
                //this.setState({ userList: res.data });
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
    }

    render() {
        return (
            <div>
                <SimpleModal body="Los cambios se han guardado correctamente" okText="Gracias!" show={this.state.savedModalShow} onClose={() => { this.setState({ savedModalShow: false }); this.loadUsers();}} />
                <h4>
                    <span className="mr-2">Usuarios</span>
                    <button className="btn btn-sm btn-success" onClick={this.UserAdd}><span className="fa fa-plus"></span></button>
                    <button className="btn btn-sm btn-primary" onClick={this.Save}><span className="fa fa-save"></span></button>
                </h4>

                <div className="row">
                    <div className="col-sm-3">Nombre</div>
                    <div className="col-sm-3">Email</div>
                    <div className="col-sm-2">Filial</div>
                    <div className="col-sm-2">Permisos</div>
                    <div className="col-sm-2">Acciones</div>
                </div>

                {this.state.userList.map((r, i) =>
                    <div className={"row " + (r.rowStatus == Constants.STATUS_EDIT ? "bg-warning" : (r.rowStatus == Constants.STATUS_NEW ? "bg-success" : ""))} key={r.userId} style={{ display: r.rowStatus != Constants.STATUS_DELETE ? '' : 'none' }}>
                        <div className="col-sm-3"><input className="form-control" type="text" value={r.name} onChange={(e) => { this.UserChangeName(r, e); }} /></div>
                        <div className="col-sm-3"><input className="form-control" type="text" value={r.email} onChange={(e) => { this.UserChangeEmail(r, e); }} /></div>
                        <div className="col-sm-2">
                            <select className="form-control" defaultValue={r.building.buildingId} disabled={this.state.user.userType > 1} onChange={(e) => this.UserChangeBuilding(r, e)}>
                                {this.state.buildingList.map((b, ib) =>
                                    <option value={b.buildingId} key={ib}>{b.code}</option>
                                    )
                                }
                            </select>
                        </div>
                        <div className="col-sm-2">
                            <select className="form-control" defaultValue={r.userType} disabled={this.state.user.userType > 1} onChange={(e) => this.UserChangeUserType(r, e)}>
                                <option value="0">Admin</option>
                                {false && <option value="1">Admin - Lectura</option>
                                }
                                <option value="2">Resid</option>
                                <option value="3">Seg</option>
                            </select>
                        </div>
                        <div className="col-sm-2">
                            <button className="btn btn-sm btn-danger" onClick={() => { this.UserDelete(r); }} value={r.userId}><span className="fa fa-trash"></span></button>
                            <button className="btn btn-sm btn-warning" onClick={() => { this.UserClearPassword(r); }} value={r.userId}><span className="fa fa-key"></span></button>
                        </div>
                    </div>
                )
                }
            </div>
        );
    }
}

export default AdminUsers;