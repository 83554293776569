import React from 'react';
import Storage from '../common/Storage';
import axios from 'axios';
import Constants from '../common/Constants';
import SimpleModal from '../common/SimpleModal';

class AuthorizedGuests extends React.Component {
    constructor(props) {
        super(props);

        let user = Storage.getItem('CurrentUser');
        let building = this.props.building;
        this.state = { user, building, showModal: false };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.building) {
            if (this.state.building && this.state.building.buildingId === this.props.building.buildingId) { return; }
            this.setState({ building: this.props.building})
            return;
        }

        if (this.state.building && prevState.building) {
            if (this.state.building.buildingId === prevState.building.buildingId) { return; }
        }

        if (!this.state.user.building) { return;}
        if (this.state.building && this.state.user.building.buildingId != this.state.building.buildingId) { return; }

        let buildingId = this.state.user.building.buildingId;
        axios.get(Constants.URL_API + 'Building/GetById?id=' + buildingId, { headers: Storage.getAuth() })
            .then(res => {
                if (res.data.length > 0) { this.setState({ building: res.data[0] });}
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
    }

    AuthorizedGuestsChanged = (e) => {
        let building = this.state.building;
        building.authorizedGuests = e.target.value;
        this.setState({ building });
    }

    OwnersChanged = (e) => {
        let building = this.state.building;
        building.owners = e.target.value;
        this.setState({ building });
    }

    Save = () => {
        let building = this.state.building;
        //console.log(data);

        axios.put(Constants.URL_API + 'Building/Update', building, { headers: Storage.getAuth() })
            .then(res => {
                this.setState({ showModal: true });
                if (this.props.OnSave) { this.props.OnSave(); }
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
    }

    /*SetOnSave = (f) => {
        this.setState({ OnSave: f });
    }
    */
    render() {
        return (
            <>
                <SimpleModal body="Los cambios se han guardado correctamente" show={this.state.showModal} okClick={this.state.modalOkClick} okText="Gracias!" onClose={() => { this.setState({ showModal: false }) }} />
                <div className="d-flex flex-column" id="content-wrapper" >
                    <div id="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-2">Propietarios: </div>
                                <div className="col-sm-10">
                                    <textarea className="form-control" value={this.state.building ? (this.state.building.owners ? this.state.building.owners : '') : ''} onChange={this.OwnersChanged} rows="3" readOnly={!(this.state.user.userType == 0 || this.state.user.userType == 2) } />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-2">Autorizados: </div>
                                <div className="col-sm-10">
                                    <textarea className="form-control" value={this.state.building ? (this.state.building.authorizedGuests ? this.state.building.authorizedGuests : '') : ''} onChange={this.AuthorizedGuestsChanged} rows="3" readOnly={!(this.state.user.userType == 0 || this.state.user.userType == 2)} />
                                </div>
                            </div>
                            <br />
                            {
                                (this.state.user.userType == 0 || this.state.user.userType == 2) &&
                                <div className="row">
                                    <div className="col-sm-2"></div>
                                    <div className="col-sm-10">
                                        <button className="btn btn-block btn-info" onClick={this.Save}>Guardar Autorizados</button>
                                    </div>
                                    
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default AuthorizedGuests