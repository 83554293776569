import React from 'react';
import axios from 'axios';
import { Redirect } from 'react-router';
import ApiResultMessage from '../common/ApiResultMessage';
import Storage from '../common/Storage';
import Constants from '../common/Constants';
import { BrowserRouter as Router } from 'react-router-dom';
import Card from 'react-bootstrap/Card';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            pw: '',
            pwconf: '',
            apiCode: -1,
            loginSuccess: false,
            formStatus: 0,
            support: false
        };
        document.body.className = "bg-gradient-primary";
    }

    hideApiMessage = () => {
        this.setState({ apiCode: -1});
    }

    btnLoginClick = (e) => {
        e.preventDefault();
        if (!this.state.email) { return; }
        var restCmd = Constants.URL_API + 'User/Login?email=' + this.state.email + "&password=" + this.state.pw;
        //alert(restCmd);
        axios.post(restCmd)
            .then(res => {
                //console.log(res);
                this.setState({ apiCode: -1, loginSuccess: true });
                
                Storage.setItem('CurrentUser', JSON.stringify(res.data));
                window.location = "/";
            })
            .catch((error) => { 
                // Error
                if (error.response) {
                    //console.log(error.response.data); 
                    //console.log(error.response.status);
                    //console.log(error.response.headers);
                    if (error.response.status == 428) { this.setState({ formStatus: 1, apiCode: -1 }); }
                    else if (error.response.status == 412) { this.setState({ formStatus: 2, apiCode: -1 }); }
                    else {
                        this.setState({ apiCode: error.response.status });
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    this.setState({ apiCode: 0 });
                    //console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
                //console.log(error.config);
            });
    }

    btnPasswordSave = (e) => {
        e.preventDefault();
        if (this.state.pw.length < 6) { this.setState({ apiCode: -201 }); return; }
        if (this.state.pw != this.state.pwconf) { this.setState({ apiCode: -202 }); return; }

        this.setState({ apiCode: -1 });
        axios.post(Constants.URL_API + 'User/SetPw?email=' + this.state.email + '&password='+this.state.pw)
            .then(res => {
                //console.log(res);
                this.setState({ apiCode: -1, loginSuccess: true });

                Storage.setItem('CurrentUser', JSON.stringify(res.data));
                window.location = "/";
            })
            .catch((error) => {
                // Error
                if (error.response) {
                    //console.log(error.response.data); 
                    //console.log(error.response.status);
                    //console.log(error.response.headers);
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    // The request was made but no response was received
                    this.setState({ apiCode: 0 });
                    //console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
                //console.log(error.config);
            });
    }

    btnStartOver = () => { this.setState({ formStatus: 0, pw: '', pwconf: '' }); }

    txtEmailChange = (e) => { this.setState({ email: e.target.value }); }
    PwConfChange = (e) => { this.setState({ pwconf: e.target.value }); }
    PwChange = (e) => { this.setState({ pw: e.target.value }); }
    

    render() {
        document.body.className = "bg-black";

        if (this.state.loginSuccess) {
            return <Router><Redirect to="/home" /></Router>
        }
        else {
            return (
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-8 col-xl-6">
                            <div className="card shadow-lg o-hidden border-0 my-5">
                                <div className="card-body p-0 bg-black">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="flex-grow-1 bg-password-image" style={{ backgroundImage: "url('assets/img/logos/logo202212.png')", backgroundSize: 'contain', backgroundColor: 'black', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', height: '200px' }}></div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="p-4">
                                                <h1 className="text-dark mb-1 text-white">GESTI&#xd3;N EN L&#xed;NEA</h1>
                                                <span className="h5 text-white">Sencillo, a un solo click</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 d-none d-sm-flex" style={{ paddingRight: 0 }}>
                                            <div className="row" style={{ position: "absolute", left: "0px", top: "0px", border: "1px transparent solid", height: "50%", width: "100%" }}>
                                                <div style={{ width: "50%", borderRight: "1px white solid", height: "40%", position: "relative", top: "20%" }}></div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="p-5 bg-black text-white">
                                                <div className="text-center ">
                                                    
                                                    <p className="mb-4">
                                                        {this.state.formStatus == 0 && "Digite su email para iniciar"}
                                                        {this.state.formStatus == 1 && "Digite su contrasena dos veces para completar el registro"}
                                                        {this.state.formStatus == 2 && "Digite su contrasena para ingresar"}
                                                        </p>
                                                </div>
                                                { // Step 1: enter user email 
                                                    this.state.formStatus == 0 &&
                                                    <form className="user">
                                                        <div className="form-group mb-2">
                                                            <input autoFocus value={this.state.email} onChange={this.txtEmailChange} className="form-control form-control-user" type="email" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Email" name="email" />
                                                        </div>
                                                        
                                                        <button onClick={this.btnLoginClick} className="btn btn-primary btn-block text-white btn-user" type="submit" >Continuar</button>
                                                    </form>
                                                }
                                                

                                                { // Step 2: enter password if missing
                                                    this.state.formStatus == 1 &&
                                                    <form className="user">
                                                        <div className="form-group">
                                                            <input autoFocus value={this.state.pw} onChange={this.PwChange} className="form-control form-control-user" type="password" id="pw" placeholder="Contrasena" name="pw" />
                                                            <input value={this.state.pwconf} onChange={this.PwConfChange} className="form-control form-control-user" type="password" id="pwconf" placeholder="Confirme la Contrasena" name="pwconf" />
                                                        </div>
                                                        <button onClick={this.btnPasswordSave} className="btn btn-primary btn-block text-white btn-user" type="submit" >Guardar contrasena e iniciar</button>
                                                        <button onClick={this.btnStartOver} className="btn btn-secondary btn-block text-white btn-user" type="submit" >Volver</button>
                                                    </form>
                                                }
                                                

                                                { // Step 3: enter password once ready
                                                    this.state.formStatus == 2 &&
                                                    <form className="user">
                                                        <div className="form-group mb-2">
                                                            <input autoFocus value={this.state.pw} onChange={this.PwChange} className="form-control form-control-user" type="password" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Contrasena" name="password" />
                                                        </div>
                                                        <button onClick={this.btnLoginClick} className="btn btn-primary btn-block text-white btn-user" type="submit" >Ingresar</button>
                                                        <button onClick={this.btnStartOver} className="btn btn-secondary btn-block text-white btn-user" type="submit" >Volver</button>
                                                    </form>
                                                }
                                                
                                                <ApiResultMessage code={this.state.apiCode} clearMessage={this.hideApiMessage} messageOverride={{404: "Correo no incluido; favor contactar a la administracion."}} />
                                                <div className="text-center">
                                                    <hr />
                                                </div>
                                                {
                                                    //<div className="text-center"><span className="text-dark small">Si necesita ayuda para ingresar, Por favor contacte a la administraci�n del condominio. favor ponerse en contacto mediante el email facilitado para su condominio.</span></div>
                                                }
                                                {this.state.support ?
                                                    <div className="text-center"><span className="small text-danger">Si necesita ayuda para ingresar, por favor contacte a la administraci&oacute;n del condominio. </span></div>
                                                    :
                                                    <div className="text-center"><span className="small text-danger" onClick={() => { this.setState({ support: true }); }}>Soporte </span></div>
                                                    }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

    }
}

export default Login;