import React from 'react';

class ApiResultMessage extends React.Component {
    /*constructor(props) {
        super(props);
    }*/

    render() {
        let message = '';

        switch (this.props.code) {
            case -201: message = 'La contrasena debe ser de 6 o mas caracteres.'; break;
            case -202: message = 'Las contrasenas no coinciden'; break;

            case -101: message = 'La reservacion no se pudo realizar. La fecha es en el pasado'; break;
            case -102: message = 'La reservacion no se pudo realizar. El recurso elegido no es valido.'; break;
            case -103: message = 'La reservacion no se pudo realizar. La filial elegida no es valida.'; break;
            case -104: message = 'La reservacion no se pudo realizar. El recurso esta bloqueado por un uso previo.'; break;
            case -105: message = 'La reservacion no se pudo realizar. El recurso ya esta reservado a la misma fecha y hora.'; break;
            case -106: message = 'La reservacion no se pudo realizar. El recurso esta bloqueado por la administracion por restricciones de horario. Cambie la hora e intente nuevamente.'; break;
            case -107: message = 'La reservacion no se pudo realizar. El edificio o filial del usuario no puede reservar recursos.'; break;
            case -108: message = 'La reservacion no se pudo realizar. -108'; break;
            case -109: message = 'La reservacion no se pudo realizar. -109'; break;
            case -110: message = 'La reservacion no se pudo realizar: Las reservaciones permitidas para su filial se agotaron en fechas anteriores.'; break;
            case -111: message = 'La reservacion no se pudo realizar: Las reservaciones permitidas para su filial se agotaron en fechas futuras.'; break;
            case -112: message = 'La reservacion no se pudo realizar: Ha alcanzado el limite de reservas futuras permitidas.'; break;
            case -113: message = 'La reservacion no se pudo realizar: La solicitud esta fuera del periodo actual permitido.'; break;
            case -114: message = 'La reservacion no se pudo realizar: Con sus reservas anteriores ha alcanzado el limite de reservas totales permitidas en el periodo en curso para este dia de la semana en particular.'; break;
            case -115: message = 'La reservacion no se pudo realizar: Con sus reservas futuras ha alcanzado el limite de reservas totales permitidas en el periodo en curso para este dia de la semana en particular.'; break;
            case -1: message = ''; break;
            case 401: message = 'Usuario no autorizado para esta operacion'; break;
            case 403: message = 'Contrasena invalida'; break;
            case 404: message = 'El elemento no fue encontrado'; break;
            case 412: message = 'Se ha llegado al limite permitido.'; break;
            case 499: message = 'La reservacion no se pudo realizar. Revise que la fecha sea en el futuro, que no exista una reservacion para la misma fecha por otro afiliado, y su casa no haya hecho reservas consecutivas.'; break;
            case 500: message = 'Error interno del sistema. Lo estaremos revisando y solucionando en breve.'; break;
            case 0: message = 'Error de comunicacion; intente nuevamente'; break;
            default: message = 'Error desconocido. Lo estaremos revisando y solucionando en breve.';
        }

        if (this.props.messageOverride && this.props.messageOverride[this.props.code]) {
            message = this.props.messageOverride[this.props.code];
        }

        return (message.length == 0 ? null :
            <div className="mt-2 alert alert-danger alert-dismissible fade show">
                <span>{message}</span>
                <button type="button" className="close" data-dismiss="alert" onClick={this.props.clearMessage}>&times;</button>
            </div>
        );
    }
}

export default ApiResultMessage;