import React from 'react';
import Storage from '../common/Storage';
import axios from 'axios';
import Constants from '../common/Constants';
import SimpleModal from '../common/SimpleModal';
import ApiResultMessage from '../common/ApiResultMessage';

class PwReset extends React.Component {
    constructor(props) {
        super(props);

        let user = Storage.getItem('CurrentUser');
        this.state = {
            user,
            pw: "",
            formDisabled: false,
            apiCode:-1
        };
    }

    componentDidMount() { }

    PwChanged = (e) => {
        this.setState({ pw: e.target.value});
    }

    OnPwResetClick = () => {
        this.setState({ formDisabled: true, apiCode: -1 });
        this.Login();
    }

    Login = () => {
        var restCmd = Constants.URL_API + 'User/Login?email=' + this.state.user.email + "&password=" + this.state.pw;

        axios.post(restCmd)
            .then(res => {
                //console.log(res);
                this.setState({ apiCode: -1, loginSuccess: true });
                this.SaveEmptyPw(res.data);
            })
            .catch((error) => {
                // Error
                if (error.response) {
                    if (error.response.status == 428) { this.setState({ formStatus: 1, apiCode: -1 }); }
                    else if (error.response.status == 412) { this.setState({ formStatus: 2, apiCode: -1 }); }
                    else {
                        this.setState({ apiCode: error.response.status });
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    this.setState({ apiCode: 0 });
                    //console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
                //console.log(error.config);
                this.setState({ formDisabled: false });
            });
    }

    SaveEmptyPw = (user) => {
        var user = this.state.user;
        user.pwHash = "";

        var restCmd = Constants.URL_API + 'User/Update';
        //alert(restCmd);
        axios(restCmd, { method: "put", data: user, headers: Storage.getAuth() })
            .then(res => {
                //console.log(res);
                this.Logout();
            })
            .catch((error) => {
                // Error
                if (error.response) {
                    if (error.response.status == 428) { this.setState({ formStatus: 1, apiCode: -1 }); }
                    else if (error.response.status == 412) { this.setState({ formStatus: 2, apiCode: -1 }); }
                    else {
                        this.setState({ apiCode: error.response.status });
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    this.setState({ apiCode: 0 });
                    //console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
                //console.log(error.config);
                this.setState({ formDisabled: false });
            });
    }

    Logout = () => {
        Storage.clear();
        window.location = "/";
    }

    render() {
        return (
            <>
                <h2>Reiniciar contrase&ntilde;a</h2>
                <p>Al solicitar el reinicio de la contrase&ntilde;a, la sesion se cerrara en todos los dispositivos donde haya ingresado. Entonces se le solicitara volver a ingresar y generar una nueva contrase&ntilde;a.</p>
                <span>Contrase&ntilde;a actual</span>
                <div className="input-group mb-2 mr-sm-2">
                    
                    <div className="input-group-prepend">
                        <div className="input-group-text"><span className="fa fa-lock"></span></div>
                    </div>
                    <input type="password" className="form-control" value={this.state.pw} onChange={this.PwChanged} />

                </div>
                {
                    this.state.pw && <button className="btn btn-warning" onClick={this.OnPwResetClick} disabled={this.state.formDisabled}>Solicitar reinicio de contrase&ntilde;a</button>
                }
                <ApiResultMessage code={this.state.apiCode} clearMessage={this.hideApiMessage} />
            </>
        )
    }

}

export default PwReset