import React from 'react';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import esLocale from '@fullcalendar/core/locales/es';
import Storage from '../common/Storage';
import "@fullcalendar/core/main.css"; 
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import ResidentialsSelect from '../common/ResidentialSelect';
import BuildingSelect from '../common/BuildingSelect';
import Constants from '../common/Constants';
import ApiResultMessage from '../common/ApiResultMessage';
import axios from 'axios';
import moment from 'moment';
import SimpleModal from '../common/SimpleModal';
import VisitItem from '../visit/VisitItem';
import AuthorizedGuests from './AuthorizedGuests';

class Visit extends React.Component {
    calendarComponentRef = React.createRef();
    
    constructor(props) {
        super(props);

        let user = Storage.getItem('CurrentUser');
        let showResidentials = (user.userType < 2 ? true : false);
        let showBuildings = (user.userType != 2 ? true : false);
        let selectedResidentialId = (user.building ? user.building.residential.residentialId : null);
        //let selectedBuilding = (user.userType == 2 ? user.building : null);
        let selectedBuilding = null;
        this.state = {
            user,
            esLocale: esLocale,
            calendarWeekends: true,
            calendarEvents: [],
            showResidentials,
            showBuildings,
            selectedResidentialId,
            selectedBuilding,
            maxLockMinutes: 0,
            apiCode: -1
        }; 
    }

    hideApiMessage = () => {
        this.setState({ apiCode: -1 });
    }

    showModalAlert = (body) => {
        this.setState({
            showModal: true,
            modalBody: body,
            modalOkText: "Entendido",
            modalCancelText: "",
            modalOkClick:    () => { this.setState({ showModal: false }) }
        });
    }

    showModalConfirm(body, okText, cancelText, okClick) {
        this.showModalAlert(body);
        this.setState({
            modalOkText: okText,
            modalCancelText: cancelText,
            modalOkClick: okClick
        });
    }

    onCalendarClick = arg => {        
        let calendarApi = this.calendarComponentRef.current.getApi();
        if (calendarApi.state.viewType == 'dayGridMonth') {
            calendarApi.changeView('timeGridDay', arg.dateStr);
            return;
        }

        if (this.state.user.userType == 1) { return;}
        if (arg.allDay) {
            this.showModalAlert("Solo se pueden resevar por horas. Por favor abra la agenda para un dia en particular.");
            return;
        }

        if (this.state.user.userType == 0 && !this.state.selectedBuilding) {
            this.showModalAlert("Es necesario elegir una filial primero.");
            return;
        }

        let currentVisit = {
            visitors: "",
            timeStart: arg.dateStr.replace('Z', ''),
            building: this.state.selectedBuilding,
        };
        var startdate = moment(currentVisit.timeStart);
        var returned_endate = moment(startdate).add(1, 'hours');
        currentVisit.timeEnd = returned_endate.format(); 
        this.setState({ showItemModal: true, currentVisit, action: Constants.STATUS_NEW });
    }

    refreshVisits = (id) => {
        if (!id) { id = this.state.selectedResidentialId; }
        this.setState({ showItemModal: false })

        axios.get(Constants.URL_API + 'visit/GetAll?id='+id, { headers: Storage.getAuth() })
            .then(res => {
                res.data.map(r => {
                    r.title = "#" + r.building.code + " // " + r.visitors;
                    r.start = r.timeStart;
                    r.end = r.timeEnd;
                    r.className = (r.visitType ? 'resource_' + r.visitType.visitTypeId : "");
                    return r;
                });
                this.setState({ calendarEvents: res.data });
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
    }

    onChangeResidential = (e) => {
        let residential = JSON.parse(e.target.value);
        let id = residential.residentialId;
        this.setState({ selectedResidentialId: id });
        this.refreshVisits(id);
        this.setState({ selectedBuilding : null});
    }

    onChangeBuilding = (e) => {
        let selectedBuilding = JSON.parse(e.target.value);
        this.setState({ selectedBuilding });
        //this.refreshVisits(id); // TODO: pull visits of this buliding only
    }

    onEventClick = (e) => {
        //if (this.state.user.userType != 0 && this.state.user.building.buildingId != e.event.extendedProps.building.buildingId) { return; }
        //this.setState({ selectedVisitId: e.event.extendedProps.visitId});
        //this.showModalConfirm("Desea eliminar esta visita?", "Si, eliminarla", "No, conservarla", this.doDelete);
        let visit = e.event.extendedProps;

        //this.setState({ showItemModal: true, currentVisit: { visitId: visit.visitId, timeStart: visit.timeStart, timeEnd: visit.timeEnd, visitors: visit.visitors }, action: Constants.STATUS_EDIT });
        this.setState({ showItemModal: true, currentVisit: { ...visit}, action: Constants.STATUS_EDIT });
    }

    componentDidMount() {
        if (this.state.selectedResidentialId) {
            this.refreshVisits(this.state.selectedResidentialId);
        }
    }

    SaveVisit = () => {
        this.SaveVisitItem();
    }

    AutorizedGuestsSaved = () => {
        this.setState({ forceRefreshBuildings: true });
        this.setState({ forceRefreshBuildings: false });
    }

    render() {
        return (
            <>
                <SimpleModal body={this.state.modalBody} show={this.state.showModal} okClick={this.state.modalOkClick} okText={this.state.modalOkText} cancelText={this.state.modalCancelText} onClose={() => { this.setState({ showModal: false }) }} />
                <SimpleModal show={this.state.showItemModal} okClick={this.SaveVisit} okText={this.state.user.userType == 0 || this.state.user.userType == 2 ? "Guardar" : null } cancelText="Descartar" onClose={() => { this.setState({ showItemModal: false }) }}>
                    <VisitItem visit={this.state.currentVisit} action={this.state.action} setSave={s => this.SaveVisitItem = s} onVisitSaved={this.refreshVisits} />
                </SimpleModal>
                <div className="d-flex flex-column" id="content-wrapper">
                    <div id="content">
                        <div className="container-fluid">
                            <h3 className="text-dark mb-1">Visitas</h3>
                            <hr />
                            {this.state.showResidentials && <ResidentialsSelect onDropdownSelected={this.onChangeResidential} showLabel={true} />}
                            {this.state.showBuildings && this.state.selectedResidentialId && <BuildingSelect residentialId={this.state.selectedResidentialId} onDropdownSelected={this.onChangeBuilding} showLabel={true} forceRefresh={this.state.forceRefreshBuildings} /> }
                            <hr />
                            <AuthorizedGuests building={this.state.selectedBuilding} OnSave={this.AutorizedGuestsSaved} />
                            <hr/>
                            <div className="row" className="center"><ApiResultMessage code={this.state.apiCode} clearMessage={this.hideApiMessage} /></div>
                            <div className="row justify-content-center features">
                                <FullCalendar
                                    defaultDate={new Date()}
                                    defaultView="dayGridMonth"
                                    header={{
                                        left: "prev,next",
                                        center: "title",
                                        right: "dayGridMonth,timeGridDay,listWeek"
                                    }}
                                    views={{
                                        dayGridMonth: {
                                            titleFormat: {
                                                month: 'short',
                                                year: 'numeric',
                                            },
                                        },
                                        timeGridDay: {
                                            titleFormat: {
                                                month: 'short',
                                                year: 'numeric',
                                                day: 'numeric'
                                            },
                                        }
                                    }}
                                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                    ref={this.calendarComponentRef}
                                    weekends={this.state.calendarWeekends}
                                    events={this.state.calendarEvents}
                                    dateClick={this.onCalendarClick}
                                    eventClick={this.onEventClick}
                                    locale={this.state.esLocale}
                                    navLinks="true"
                                    slotDuration="01:00:00"
                                    allDaySlot={false}
                                    timeZone="UTC"
                                    slotEventOverlap="false"
                                    eventTextColor="white"
                                    eventRender={(e) => this.state.selectedBuilding ? e.event.extendedProps.building.buildingId == this.state.selectedBuilding.buildingId : true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Visit;