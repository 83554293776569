import React from 'react';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import esLocale from '@fullcalendar/core/locales/es';
import Storage from '../common/Storage';
import "@fullcalendar/core/main.css"; 
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import ResidentialsSelect from '../common/ResidentialSelect';
import BuildingSelect from '../common/BuildingSelect';
import ResourceSelect from '../common/ResourceSelect';
import Constants from '../common/Constants';
import ApiResultMessage from '../common/ApiResultMessage';
import axios from 'axios';
import SimpleModal from '../common/SimpleModal';
import { Link } from 'react-router-dom';
import AdminReserveItem from '../admin/AdminReserveItem';

class Reserve extends React.Component {
    calendarComponentRef = React.createRef();
    
    constructor(props) {
        super(props);

        let user = Storage.getItem('CurrentUser');
        let showResidentials = (user.userType < 2 ? true : false);
        let selectedResidentialId = (user.building ? user.building.residential.residentialId : 0);
        this.state = {
            user,
            esLocale: esLocale,
            calendarWeekends: true,
            calendarEvents: [],
            showResidentials,
            selectedResidentialId,
            maxLockMinutes: 0,
            selectedResource: {},
            apiCode: -1,
            reservation: {}
        }; 
    }

    hideApiMessage = () => {
        this.setState({ apiCode: -1 });
    }

    showModalAlert = (body) => {
        this.setState({
            showModal: true,
            modalBody: body,
            modalOkText: "Entendido",
            modalCancelText: "",
            modalOkClick:    () => { this.setState({ showModal: false }) }
        });
    }

    modalOkReserveClick = () => {
        let res = this.doReserve();
    }

    newReservationResult = (status, data) => {
        if (status >= 200 && status < 300 && data > 0) { this.afterSave(); }
        else
        {
            if (data < 0) { this.setState({ apiCode: -100 + data }); }
            else { this.setState({ apiCode: status }); }
        }
    }

    showModalConfirm(body, okText, cancelText, okClick) {
        this.showModalAlert(body);
        this.setState({
            modalOkText: okText,
            modalCancelText: cancelText,
            modalOkClick: okClick,
        });
    }

    showModalCreate() {
        let reservationBundle = {
            reservation: this.getReservationObj(),
            selectedBuilding: this.state.selectedBuilding,
            selectedResource: this.state.selectedResource
        };

        this.setState({
            showModalCreate: true,
            modalOkText: "Si, reservar",
            modalCancelText: "No gracias",
            modalOkClick: this.doReserve,
            reservationBundle
        });
    }

    afterSave = () => {
         this.showModalAlert("Se ha completado la reservacion con exito.");
         this.refreshReservations(this.state.selectedResidentialId);
    }

    onCalendarClick = arg => {
        // ---------------  CALENDAR DRILLDOWN -----------------
        //console.log(arg);
        let calendarApi = this.calendarComponentRef.current.getApi();
        if (calendarApi.state.viewType == 'dayGridMonth' && ( /*!(this.state.selectedResource.resourceId) ||*/ this.state.selectedResource.periods >= 1)) {
            calendarApi.changeView('timeGridDay', arg.dateStr);
            return;
        }
        if (calendarApi.state.viewType == 'timeGridDay' && (/*!(this.state.selectedResource.resourceId) ||*/ this.state.selectedResource.periods == 0)) {
            calendarApi.changeView('dayGridMonth', arg.dateStr);
            return;
        }


        // ---------------  CREATE RESERVATION -----------------
        //if (this.state.user.userType < 2) { return;}
        if (this.state.user.userType > 2) { return; } // exit if security guard, but allow admins and residents to create a reservation

        if (!this.state.selectedResource.resourceId) {
            this.showModalAlert("Elija un recurso primero");
            return;
        }

        let selectedBuilding = (this.state.user.userType >= 2 ? this.state.user.building : this.state.selectedBuilding);
        if (!selectedBuilding) {
            this.showModalAlert("Elija una filial primero");
            return;
        }
        /*if (arg.allDay) {
            this.showModalAlert("Solo se pueden resevar por horas. Por favor abra la agenda para un dia en particular.");
            return;
        }*/
        this.setState({ selectedDate: arg.date, selectedBuilding });
        this.showModalCreate();
    }

    getReservationObj = () => {
        
        console.log((new Date(this.state.selectedDate.getTime())).toJSON());
        console.log((new Date(this.state.selectedDate.getTime() + this.state.selectedResource.maxLockMinutes * 60000)).toJSON());

        let data = {
            ResourceId: this.state.selectedResource.resourceId,
            BuildingId: this.state.selectedBuilding.buildingId,
            TimeStart: this.state.selectedDate,
            TimeEnd: new Date(this.state.selectedDate.getTime() + this.state.selectedResource.maxLockMinutes * 60000)
        };
        if (this.state.selectedResource.periods == 0) { // is whole day
            data.TimeEnd.setTime(data.TimeStart.getTime() + 24 * 60 * 60 * 1000 - 60 * 1000);
        }

        return data;
    }

    onChangeResource = (e) => {
        let resource = JSON.parse(e.target.value);
        this.setState({ selectedResource: resource });
    }

    onChangeBuilding = (e) => {
        let building = JSON.parse(e.target.value);
        this.setState({ selectedBuilding: building });
    }

    onChangeResidential = (e) => {
        let residential = JSON.parse(e.target.value);
        let id = residential.residentialId;
        this.setState({ selectedResidentialId: id });
        this.refreshReservations(id);
    }

    refreshReservations = (id) => {
        axios.get(Constants.URL_API + 'reservation/GetAll?id='+id, { headers: Storage.getAuth() })
            .then(res => {
                res.data.map(r => {
                    r.title = "#" + r.building.code + ' - ' + r.resource.name;
                    r.start = r.timeStart;
                    r.end = r.timeEnd;
                    r.className = 'resource_' + r.resource.resourceId;
                    return r;
                });
                this.setState({ calendarEvents: res.data });
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
    }

    

    onEventClick = (e) => {
        var reservation = e.event.extendedProps;
        //console.log(reservation);
        //if (this.state.user.userType != 0 && this.state.user.building.buildingId != e.event.extendedProps.building.buildingId) { return; }
        this.setState({ selectedReservationId: e.event.extendedProps.reservationId});
        //this.showModalConfirm("Desea eliminar esta reservacion?", "Si, eliminarla", "No, conservarla", this.doUnreserve);
        var action = (this.state.user.userType != 0 && this.state.user.building.buildingId != e.event.extendedProps.building.buildingId ? null : this.doUnreserve)
        var actionText = (action ? "Eliminar" : null)
        this.showModalConfirm("Detalles: " + reservation.resource.name +" [" + reservation.building.code + "] @ " + reservation.timeStart.replace("T"," ") , actionText, "Cerrar", action);
    }

    onNavLinkDayClick = (e) => {
        var arg = { date: e, dateStr: e.toISOString().split('T')[0] };
        //console.log(arg);
        this.onCalendarClick(arg);
        //this.showModalAlert("Seleccione la parte blanca de la casilla.");
    }

    doUnreserve = () => {
        axios.delete(Constants.URL_API + 'reservation/Remove/?id=' + this.state.selectedReservationId, { headers: Storage.getAuth() })
            .then(res => {
                this.refreshReservations(this.state.selectedResidentialId);
                this.showModalAlert("Se ha eliminado la reservacion con exito.");
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
    }

    componentDidMount() {
        if (this.state.selectedResidentialId) {
            this.refreshReservations(this.state.selectedResidentialId);
        }
    }

    render() {      
        return (
            <>
                <SimpleModal body={ this.state.modalBody } show={this.state.showModal} okClick={this.state.modalOkClick} okText={this.state.modalOkText} cancelText={this.state.modalCancelText} onClose={() => { this.setState({ showModal: false }) }}  ></SimpleModal>

                <SimpleModal show={this.state.showModalCreate} okClick={this.modalOkReserveClick} okText={this.state.modalOkText} cancelText={this.state.modalCancelText} onClose={() => { this.setState({ showModalCreate: false }) }}  >
                    <AdminReserveItem reservationBundle={this.state.reservationBundle} setSave={s => this.doReserve = s} resultCallback={this.newReservationResult} />
                </SimpleModal>
                
                <div className="d-flex flex-column" id="content-wrapper">
                    <div id="content">
                        <div className="container-fluid">
                            <div className="row justify-content-end">
                                <div className="col-4"><h3 className="text-dark mb-1">Reservaciones</h3></div>
                                <div className="col-4"><Link to="/myreservations">Mis reservaciones <span className="fa fa-tags"></span></Link></div>
                            </div>
                            
                            <div>
                                <hr />
                            </div>
                            {this.state.showResidentials && <ResidentialsSelect onDropdownSelected={this.onChangeResidential} showLabel={true} />}
                            {this.state.selectedResidentialId > 0 && <ResourceSelect residentialId={this.state.selectedResidentialId} onDropdownSelected={this.onChangeResource} />}
                            {this.state.showResidentials && this.state.selectedResidentialId > 0 && <BuildingSelect onDropdownSelected={this.onChangeBuilding} showLabel={true} residentialId={this.state.selectedResidentialId} />}
                            <div className="row" className="center"><ApiResultMessage code={this.state.apiCode} clearMessage={this.hideApiMessage} messageOverride={{ 412:"Solo se permite eliminar una reserva con mas de 1 hora de antelacion."}} /></div>
                            <div className="row justify-content-center features">
                                <FullCalendar
                                    defaultDate={new Date()}
                                    defaultView="dayGridMonth"
                                    header={{
                                        left: "prev,next",
                                        center: "title",
                                        right: "dayGridMonth,timeGridDay,listWeek"
                                    }}
                                    views={{
                                        dayGridMonth: {
                                            titleFormat: {
                                                month: 'short',
                                                year: 'numeric',
                                            },
                                        },
                                        timeGridDay: {
                                            titleFormat: {
                                                month: 'short',
                                                year: 'numeric',
                                                day: 'numeric'
                                            },
                                        }
                                    }}
                                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                    ref={this.calendarComponentRef}
                                    weekends={this.state.calendarWeekends}
                                    events={this.state.calendarEvents}
                                    dateClick={this.onCalendarClick}
                                    navLinkDayClick={this.onNavLinkDayClick}
                                    eventClick={this.onEventClick}
                                    locale={this.state.esLocale}
                                    navLinks="true"
                                    slotDuration="01:00:00"
                                    allDaySlot={false}
                                    timeZone="UTC"
                                    slotEventOverlap="false"
                                    showNonCurrentDates={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Reserve;