import React from 'react';
import axios from 'axios';
import Constants from '../common/Constants';
import Storage from '../common/Storage';

class ResourceSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {ResourceList: []}
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.ResourceList.length > 0 && this.props.residentialId === prevProps.residentialId) {return;}

        let residentialId = (this.props.residentialId ? this.props.residentialId : 0);
        axios.get(Constants.URL_API + 'Resource/GetAll?residentialId=' + residentialId, { headers: Storage.getAuth() })
            .then(res => {
                this.setState({ ResourceList: res.data });
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
    }

    render() {
        
        return (
            <>
                <div className="form-group">
                    <label htmlFor="ResourceId">Recurso</label>
                    <select className="form-control" id="ResourceId" type="select" defaultValue="0" onChange={this.props.onDropdownSelected}>
                        <option disabled value="0"> -- Elija una opcion -- </option>
                        {this.state.ResourceList.map((e, key) => {
                            return <option key={key} value={JSON.stringify(e)} className={'resource_'+e.resourceId}>{e.name}</option>;
                        })}
                    </select>
                </div>
            </>
        );
    }
}

export default ResourceSelect;