import React from 'react';
import Storage from '../common/Storage';
import axios from 'axios';
import Constants from '../common/Constants';
import SimpleModal from '../common/SimpleModal';
import Dropdown from 'react-bootstrap/Dropdown'
import AdminResourceLock from './AdminResourceLock.jsx'

class AdminResources extends React.Component {
    constructor(props) {
        super(props);

        let user = Storage.getItem('CurrentUser');

        this.state = {
            resourceList: [],
            newId: 0,
            savedModalShow: false
        };
    }

    RefreshResources = () => {
        axios.get(Constants.URL_API + 'Resource/GetAll?residentialId=' + this.props.residentialId, { headers: Storage.getAuth() })
            .then(res => {
                this.setState({ resourceList: res.data });
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.residentialId == this.props.residentialId) { return; }
        this.RefreshResources();
    }

    ResourcePropertyUpdate = (r, newObj) => {
        let resourceList = this.state.resourceList;
        let item = resourceList.find((i) => i.resourceId === r.resourceId);
        Object.assign(item, { rowStatus: Constants.STATUS_EDIT }, newObj);
        this.setState({resourceList });
    }

    ResourceChangeMinutes = (r, e) => { this.ResourcePropertyUpdate(r, { maxLockMinutes: e.target.value });}

    ResourceChangeName = (r, e) => { this.ResourcePropertyUpdate(r, { name: e.target.value }); }
    ResourceChangePeriods = (r, e) => { this.ResourcePropertyUpdate(r, { periods: parseInt(e.target.value) }); }
    ResourceChangeResTimeUnit = (r, e) => { this.ResourcePropertyUpdate(r, { restriction_TimeUnit: parseInt(e.target.value) }); }
    ResourceChangeResTimeUnitGroup = (r, e) => { this.ResourcePropertyUpdate(r, { restriction_TimeUnitGroup: parseInt(e.target.value) }); }
    ResourceChangeResMaxQty = (r, e) => { this.ResourcePropertyUpdate(r, { restriction_MaxQuantity: parseInt(e.target.value) }); }
    // 
    ResourceChangeResAllowedReservPeriod = (r, e) => { this.ResourcePropertyUpdate(r, { restriction_AllowedReservationPeriod: parseInt(e.target.value) }); }
    ResourceChangeResAllowedReservationPeriodGroup = (r, e) => { this.ResourcePropertyUpdate(r, { restriction_AllowedReservationPeriodGroup: parseInt(e.target.value) }); }
    ResourceChangeResFutureLimit = (r, e) => { this.ResourcePropertyUpdate(r, { restriction_FutureReservationsLimit: parseInt(e.target.value) }); }
    ResourceChangeResLimitedWeekdaysLimit = (r, e) => { this.ResourcePropertyUpdate(r, { restriction_LimitedWeekdaysLimit: parseInt(e.target.value) }); }
    ResourceChangeResLimitedWeekdaysFlags = (r, e) => {
        let restriction_LimitedWeekdaysFlags = r.restriction_LimitedWeekdaysFlags ^ parseInt(e.target.value);
        this.ResourcePropertyUpdate(r, { restriction_LimitedWeekdaysFlags });
    }

    ResourceDelete = (r) => { this.ResourcePropertyUpdate(r, { rowStatus: Constants.STATUS_DELETE }); }

    ResourceAdd = () => {
        let resourceList = this.state.resourceList;
        let newId = this.state.newId - 1;
        resourceList.unshift({ resourceId: newId, name: "", maxLockMinutes: 60, userType: 2, residential: { residentialId: this.props.residentialId }, periods: 1, rowStatus: Constants.STATUS_NEW });
        this.setState({ resourceList, newId });
    }

    Save = () => {
        this.Create();
        this.Update();
        this.Delete();
        this.setState({ savedModalShow: true });
    }

    Create = () => {
        let list = this.state.resourceList.filter(u => u.resourceId < 0 && u.rowStatus == Constants.STATUS_EDIT);
        this.SaveAPI("CreateMultiple", "post", list);
    }

    Update = () => {
        let list = this.state.resourceList.filter(u => u.resourceId > 0 && u.rowStatus == Constants.STATUS_EDIT);
        this.SaveAPI("UpdateMultiple", "put", list);
    }

    Delete = () => {
        let list = this.state.resourceList.filter(u => u.resourceId > 0 && u.rowStatus == Constants.STATUS_DELETE);
        let ids = list.map(u => u.resourceId);
        this.SaveAPI("DeleteMultiple", "delete", ids);
    }

    SaveAPI = (action, method, data) => {
        if (data.length == 0) { return; }
        if (action.search(/delete/i) < 0) { data.map(i => i.maxLockMinutes = parseInt(i.maxLockMinutes)); }
        
        axios(Constants.URL_API + 'Resource/' + action, { method, data, headers: Storage.getAuth() })
            .then(res => {
                //this.setState({ userList: res.data });
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
    }

    SaveLocks = (resource) => {
        var locks = JSON.parse(JSON.stringify(resource.locks)); ;
        locks.map(l => {
            l.timeStart = "2000-01-01T"+ l.timeStart;
            l.timeEnd = "2000-01-01T" + l.timeEnd;
        });
        this.ResourcePropertyUpdate(resource, { locks });
    }

    render() {
        return (
            <>
                <SimpleModal body="Los cambios se han guardado correctamente" okText="Gracias!" show={this.state.savedModalShow} onClose={() => { this.setState({ savedModalShow: false }); this.RefreshResources(); }} />
                <h4>
                    <span className="mr-2">Recursos</span>
                    <button className="btn btn-sm btn-success" onClick={this.ResourceAdd}><span className="fa fa-plus"></span></button>
                    <button className="btn btn-sm btn-primary" onClick={this.Save}><span className="fa fa-save"></span></button>
                </h4>

                {this.state.resourceList.map((r, i) =>
                    <div className="card" key={r.resourceId}>
                        <div className="card-body">
                            <div className={(r.rowStatus == Constants.STATUS_EDIT ? "bg-secondary" : (r.rowStatus == Constants.STATUS_NEW ? "bg-success" : ""))} key={r.resourceId} style={{ display: r.rowStatus != Constants.STATUS_DELETE ? '' : 'none' }}>
                                <div className="row">
                                    <div className="col-sm-3">Nombre: </div>
                                    <div className="col-sm-2"><input className="form-control" type="text" value={r.name} onChange={(e) => { this.ResourceChangeName(r, e); }} /></div>

                                    <div className="col-sm-3">Periodo Seleccion:</div>
                                    <div className="col-sm-2">
                                        <select className="form-control" defaultValue={r.periods} onChange={(e) => this.ResourceChangePeriods(r, e)}>
                                            <option value="0">Dia completo</option>
                                            <option value="1">Rango de tiempo</option>
                                            <option value="2">Libre</option>
                                        </select>
                                    </div>

                                    <div className="col-sm-1">
                                        <button className="btn btn-sm btn-danger" onClick={() => { this.ResourceDelete(r); }} value={r.resourceId}><span className="fa fa-trash"></span></button>
                                    </div>
                                </div>
                                <hr/>
                                <div className="row">
                                    <div className="col-sm-12">
                                        Duraci&oacute;n en minutos de Reserva:
                                        <input style={{ width: "50px" }} type="number" value={r.maxLockMinutes} onChange={(e) => { this.ResourceChangeMinutes(r, e); }} />
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-sm-12">Restricciones: Se permite un total de <input style={{ width:"50px"}} type="number" value={r.restriction_MaxQuantity} onChange={(e) => { this.ResourceChangeResMaxQty(r, e); }} />
                                        reservas en
                                        <input type="number" style={{ width: "50px" }} value={r.restriction_TimeUnitGroup} onChange={(e) => { this.ResourceChangeResTimeUnitGroup(r, e); }} />
                                        <select defaultValue={r.restriction_TimeUnit} onChange={(e) => this.ResourceChangeResTimeUnit(r, e)}>
                                            <option value="0">Minuto</option>
                                            <option value="1">Hora</option>
                                            <option value="2">Dia</option>
                                            <option value="3">Semana</option>
                                            <option value="4">Mes</option>
                                            <option value="5">A&#xf1;o</option>
                                        </select>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-sm-12">
                                        Periodo habilitado: El periodo habilitado para reservar consiste en
                                        <input style={{ width: "50px" }} type="number" value={r.restriction_AllowedReservationPeriodGroup} onChange={(e) => { this.ResourceChangeResAllowedReservationPeriodGroup(r, e); }} />
                                        <select defaultValue={r.restriction_AllowedReservationPeriod} onChange={(e) => this.ResourceChangeResAllowedReservPeriod(r, e)}>
                                            <option value="0">Minuto</option>
                                            <option value="1">Hora</option>
                                            <option value="2">Dia</option>
                                            <option value="3">Semana</option>
                                            <option value="4">Mes</option>
                                            <option value="5">A&#xf1;o</option>
                                        </select>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-sm-12">Cantidad de reservas futuras permitidas: M&aacute;ximo
                                        <input style={{ width: "50px" }} type="number" value={r.restriction_FutureReservationsLimit} onChange={(e) => { this.ResourceChangeResFutureLimit(r, e); }} /></div>
                                </div>
                                <hr />
                                <div className="row">
                                    
                                    <div className="col-sm-12" >
                                        Restricciones por dia de semana: M&aacute;ximo 
                                        <input style={{ width: "50px" }} type="number" value={r.restriction_LimitedWeekdaysLimit} onChange={(e) => { this.ResourceChangeResLimitedWeekdaysLimit(r, e); }} />
                                        los dias
                                        <Dropdown style={{ display: "inline" }}>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ display: "inline" }}>
                                                {(r.restriction_LimitedWeekdaysFlags & 1) > 0 && <span>L</span>}
                                                {(r.restriction_LimitedWeekdaysFlags & 2) > 0 && <span>K</span>}
                                                {(r.restriction_LimitedWeekdaysFlags & 4) > 0 && <span>M</span>}
                                                {(r.restriction_LimitedWeekdaysFlags & 8) > 0 && <span>J</span>}
                                                {(r.restriction_LimitedWeekdaysFlags & 16) > 0 && <span>V</span>}
                                                {(r.restriction_LimitedWeekdaysFlags & 32) > 0 && <span>S</span>}
                                                {(r.restriction_LimitedWeekdaysFlags & 64) > 0 && <span>D</span>}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item><button className="btn btn-sm btn-default" value="1" onClick={(e) => { this.ResourceChangeResLimitedWeekdaysFlags(r, e); }}>Lunes</button></Dropdown.Item>
                                                <Dropdown.Item><button className="btn btn-sm btn-default" value="2" onClick={(e) => { this.ResourceChangeResLimitedWeekdaysFlags(r, e); }}>Martes</button></Dropdown.Item>
                                                <Dropdown.Item><button className="btn btn-sm btn-default" value="4" onClick={(e) => { this.ResourceChangeResLimitedWeekdaysFlags(r, e); }}>Miercoles</button></Dropdown.Item>
                                                <Dropdown.Item><button className="btn btn-sm btn-default" value="8" onClick={(e) => { this.ResourceChangeResLimitedWeekdaysFlags(r, e); }}>Jueves</button></Dropdown.Item>
                                                <Dropdown.Item><button className="btn btn-sm btn-default" value="16" onClick={(e) => { this.ResourceChangeResLimitedWeekdaysFlags(r, e); }}>Viernes</button></Dropdown.Item>
                                                <Dropdown.Item><button className="btn btn-sm btn-default" value="32" onClick={(e) => { this.ResourceChangeResLimitedWeekdaysFlags(r, e); }}>Sabado</button></Dropdown.Item>
                                                <Dropdown.Item><button className="btn btn-sm btn-default" value="64" onClick={(e) => { this.ResourceChangeResLimitedWeekdaysFlags(r, e); }}>Domingo</button></Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>

                                    <div className="col-sm-3"></div>
                                    <div className="col-sm-2"></div>

                                </div>
                                <div className="row">&nbsp;</div>
                                <div className="row">
                                    {r.resourceId > 0 &&
                                        <AdminResourceLock resource={r} setSave={this.SaveLocks} />
                                    }
                                </div>
                                
                            </div>
                        </div>
                        <br /><br /><br />
                    </div>

                )}
            </>
        );
    }
}

export default AdminResources;