import React from 'react';
import { Link } from 'react-router-dom';
import Storage from './Storage';
import SimpleModal from './SimpleModal';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown';
//import Storage from './Storage';

class Menu extends React.Component {
    constructor(props) {
        super(props);

        let user = Storage.getItem('CurrentUser');
        this.state = {
            user
        };
    }

    logoutHandler = () => {
        this.setState({ logoutModalShow: true});
    }

    doLogout = () => {
        Storage.clear();
        window.location = "/";
    }

    render() {
        return (
            <>
                <Navbar variant="dark" bg="black" expand="lg">
                    <Container>
                        <Navbar.Brand href="/" >
                            <img alt="" src="/assets/img/logos/logo202212.png" width="40" height="40" className="d-inline-block align-top" />
                            {false && <div className="sidebar-brand-icon rotate-n-15"><i className="fas fa-building"></i></div>}
                            Innovaciones
                            </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link as={Link} to="/profile" className="h5 text-white"><i className="fa fa-user"></i> Usuario</Nav.Link>
                                <Nav.Link as={Link} to="/reminder" className="h5 text-white"><i className="fa fa-exclamation-triangle"></i> Recordatorios</Nav.Link>
                                <Nav.Link as={Link} to="/rule" className="h5 text-white"><i className="fa fa-balance-scale"></i> Reglamento</Nav.Link>
                                <Nav.Link as={Link} to="/reserve" className="h5 text-white"><i className="fa fa-calendar-check-o"></i> Reservas</Nav.Link>
                                <Nav.Link as={Link} to="/news" className="h5 text-white"><i className="fa fa-newspaper-o"></i> Noticias</Nav.Link>
                                <Nav.Link as={Link} to="/report" className="h5 text-white"><i className="fa fa-file-text"></i> Informes</Nav.Link>
                                <Nav.Link as={Link} to="/visit" className="h5 text-white"><i className="fa fa-users"></i> Visitas</Nav.Link>
                                {this.state.user.userType == 3 &&
                                    <Nav.Link as={Link} to="/equipmentLoan" className="h5 text-white"><i className="fa fa-id-badge"></i> Equipo</Nav.Link>
                                }
                                {this.state.user.userType < 2 &&
                                    <Nav.Link as={Link} to="/admin" className="h5 text-white"><i className="fa fa-cog"></i> Administracion</Nav.Link>
                                }
                                <div onClick={this.logoutHandler} className="text-white nav-link h5 text-white"><i className="far fa-times-circle"></i><span>Salir</span></div>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>

                <nav className="navbar navbar-dark align-items-start sidebar sidebar-dark accordion bg-gradient-primary p-0">
                    <div className="container-fluid d-flex flex-column p-0">
                    </div>
                </nav>
                <SimpleModal show={this.state.logoutModalShow} body="Realmente desea cerrar sesion?" okClick={this.doLogout} okText="Cerrar sesion" cancelText="Continuar la sesion" onClose={() => {this.setState({ logoutModalShow: false });}} />
            </>
        );
    }
}

export default Menu;