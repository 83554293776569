import React from 'react';
import Storage from '../common/Storage';
import axios from 'axios';
import Constants from '../common/Constants';
import TimePicker from 'react-time-picker';

class VisitItem extends React.Component {
    constructor(props) {
        super(props);

        let user = Storage.getItem('CurrentUser');

        let visit = this.props.visit;
        this.state = {
            user,
            visit,
            visitTypeList:[],
            timeStart: visit.timeStart.substr(11, 8),
            timeEnd: visit.timeEnd.substr(11, 8)
        };
    }

    componentDidMount() {
        this.props.setSave(this.Save);
        this.LoadVisitTypes();
    }

    Save = () => {
        if (!this.state.visit.visitType) { return false;}
        if (this.props.action == Constants.STATUS_EDIT) { this.Update(); }
        if (this.props.action == Constants.STATUS_NEW) { this.Create(); }
    }

    Update = () => {
        this.SaveAPI("Update", "put", this.state.visit);
    }
    Create = () => {
        this.SaveAPI("New", "post", this.state.visit);
    }
    Delete = () => {
        this.SaveAPI("Delete/?id=" + this.state.visit.visitId, "delete", this.state.visit);
    }

    SaveAPI = (action, method, data) => {
        data.TimeStart = this.props.visit.timeStart.substr(0,11) + this.state.timeStart;
        data.TimeEnd = this.props.visit.timeStart.substr(0, 11) + this.state.timeEnd;
        
        axios(Constants.URL_API + 'Visit/' + action, { method, data, headers: Storage.getAuth() })
            .then(res => {
                if (this.props.onVisitSaved) { this.props.onVisitSaved(); }
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
    }

    LoadVisitTypes = () => {
        axios.get(Constants.URL_API + 'VisitType/GetAll', { headers: Storage.getAuth() })
            .then(res => {
                let visitTypeList = res.data;
                visitTypeList.sort((a, b) => (a.name > b.name ? 1 : -1));
                this.setState({ visitTypeList });
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
    }

    VisitTypeChanged = (e) => {
        let visit = this.state.visit;
        let visitType = this.state.visitTypeList.find((i) => i.visitTypeId == e.target.value);
        visit.visitType = visitType;
        this.setState({ visit });
    }

    VisitorsChanged = (e) => {
        let visit = this.state.visit;
        visit.visitors = e.target.value;
        this.setState({ visit, rowStatus: Constants.STATUS_EDIT });
    }

    TimeStartChanged = (time) => { this.setState({ timeStart: time}); }

    TimeEndChanged = (time) => { this.setState({ timeEnd: time }); }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col-sm-4">Tipo: </div>
                    <div className="col-sm-8">
                        <select className={"form-control resource_" + (this.state.visit.visitType ? this.state.visit.visitType.visitTypeId : "0") } value={this.state.visit.visitType ? this.state.visit.visitType.visitTypeId : 0} onChange={(e) => this.VisitTypeChanged(e)}>
                            <option disabled value="0"> -- Elija un tipo -- </option>
                            {
                                this.state.visitTypeList.map((i) =>
                                    <option key={i.visitTypeId} className={"resource_" + i.visitTypeId} value={i.visitTypeId}>{i.name}</option>
                                )
                            }
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">Detalles: </div>
                    <div className="col-sm-8">
                        <textarea className="form-control" value={this.state.visit.visitors} onChange={this.VisitorsChanged} rows="8" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">Inicio: </div>
                    <div className="col-sm-8">
                        <TimePicker className="form-control" onChange={this.TimeStartChanged} value={this.state.timeStart} clockIcon={null}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">Fin: </div>
                    <div className="col-sm-8">
                        <TimePicker className="form-control" onChange={this.TimeEndChanged} value={this.state.timeEnd} clockIcon={null}/>
                    </div>
                </div>
                {this.state.visit.visitId && (this.state.user.userType == 0 || this.state.user.userType == 2) &&
                    <div className="row">
                        <div className="col-sm-4"></div>
                        <div className="col-sm-8">
                            <button className="btn btn-warning" onClick={this.Delete}>Eliminar Visita</button>
                        </div>
                    </div>
                }
            </>
        );
    }
}

export default VisitItem;