import React from 'react';
import Storage from '../common/Storage';
import axios from 'axios';
import Constants from '../common/Constants';
import SimpleModal from '../common/SimpleModal';
import PwReset from './PwReset';

class Profile extends React.Component {
    constructor(props) {
        super(props);

        let user = Storage.getItem('CurrentUser');
        this.state = { user };
    }

    componentDidMount() {
        axios.get(Constants.URL_API + 'User/Profile', { headers: Storage.getAuth() })
            .then(res => {
                let user = res.data;
                this.setState({ user });
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
    }

    NameChanged = (e) => {
        let user = this.state.user;
        user.name = e.target.value;
        this.setState({ user });
    }

    Save = () => {
        let data = this.state.user;
        //console.log(data);
        axios.put(Constants.URL_API + 'User/Update', data, { headers: Storage.getAuth() })
            .then(res => {
                this.setState({ showModal: true });
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
        
        data.token = Storage.getItem('CurrentUser').token;
        Storage.setItem('CurrentUser', JSON.stringify(data));
    }

    render() {
        return (
            <>
                <SimpleModal body="Los cambios se han guardado correctamente" show={this.state.showModal} okClick={this.state.modalOkClick} okText="Gracias!" onClose={() => { this.setState({ showModal: false }) }} />
                <SimpleModal show={this.state.showModalPw} onClose={() => { this.setState({ showModalPw: false }) }} cancelText="Cancelar">
                    <PwReset />
                </SimpleModal>
                <div className="d-flex flex-column" id="content-wrapper" >
                    <div id="content">
                        <div className="container-fluid">
                            <h3 className="text-dark mb-1">Perfil</h3>
                            <div>
                                <hr />
                            </div>
                            
                            <div className="m-5 d-flex flex-column justify-content">
                                <div className="">
                                    <div className="input-group mb-2 mr-sm-2">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text"><span className="fa fa-user"></span></div>
                                        </div>
                                        <input type="text" className="form-control" value={this.state.user.name} onChange={this.NameChanged} />
                                    </div>
                                    <div className="input-group mb-2 mr-sm-2">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">@</div>
                                        </div>
                                        <input type="text" className="form-control" readOnly value={this.state.user.email} />
                                    </div>
                                    {
                                        this.state.user.building &&
                                        <>
                                            <div className="input-group mb-2 mr-sm-2">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text"><span className="fa fa-home"></span></div>
                                                </div>
                                                <input type="text" className="form-control" readOnly value={this.state.user.building.code} />
                                            </div>
                                            <div className="input-group mb-2 mr-sm-2">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text"><span className="fa fa-building"></span></div>
                                                </div>
                                                <input type="text" className="form-control" readOnly value={this.state.user.building.residential.name} />
                                            </div>
                                            <br />
                                            <div className="row">
                                                <button className="btn btn-block btn-info" onClick={this.Save}>Guardar</button>
                                            </div>
                                        </>
                                    }
                                    
                                    {
                                        this.state.user.userType == 2 &&
                                        <>
                                            <hr />
                                            <div className="row">
                                                <button className="btn btn-block btn-warning" onClick={() => { this.setState({ showModalPw: true }) }}>Cambiar contrase&ntilde;a</button>
                                            </div>
                                            
                                        </>
                                    }
                                    
                                    {
                                        this.state.user.userType == 2 &&
                                        <>
                                            <hr />
                                            <div className="row">
                                                <a className="btn btn-block btn-secondary" href={"mailto:" + this.state.user.building.residential.email+"?subject=InnovacionesCR.com - Sugerencia de "+this.state.user.name+" (Filial "+this.state.user.building.code+")&cc="+this.state.user.email} >Enviar sugerencias</a>
                                            </div>
                                        </>
                                        
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Profile