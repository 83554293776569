import React from 'react';
import axios from 'axios';
import Constants from '../common/Constants';
import Storage from '../common/Storage';

class ResidentialSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = { ResidentialList : []}
    }

    componentDidMount() {
        axios.get(Constants.URL_API + 'Residential/GetAll', { headers: Storage.getAuth() })
            .then(res => {
                this.setState({ ResidentialList: res.data });
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
    }

    render() {
        return (
            <>
                <div className="form-group">
                    {this.props.showLabel &&
                        <label htmlFor="ResidentialId">Residencial</label>
                    }
                    <select className="form-control" id="ResidentialId" type="select" defaultValue="0" onChange={this.props.onDropdownSelected}>
                        <option disabled value="0"> -- Elija un residencial -- </option>
                        {this.state.ResidentialList.map((e, key) => {
                            return <option key={key} value={JSON.stringify(e)}>{e.name}</option>;
                        })}
                    </select>
                </div>
            </>
        );
    }
}

export default ResidentialSelect;