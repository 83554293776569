import React from 'react';
import AdminReports from './AdminReports'
import AdminBuildings from './AdminBuildings'
import AdminNews from './AdminNews'
import AdminUsers from './AdminUsers'
import AdminResources from './AdminResources'
import AdminReminders from './AdminReminders'
import AdminReportTypes from './AdminReportTypes'
import AdminVisitTypes from './AdminVisitTypes'
import AdminEquipmentLoans from './AdminEquipmentLoans'
import Storage from '../common/Storage';
import { Tabs, Tab } from 'react-bootstrap';
import ResidentialSelect from '../common/ResidentialSelect';
import ReservationHistory from '../reserve/ReservationHistory';

class Admin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showBuildings: false,
            showReports: false,
            selectedResidentialId: 0,
        }
    }

    onResidentialChanged = (e) => {
        let residential = JSON.parse(e.target.value);
        let id = residential.residentialId;
        this.setState({residential, selectedResidentialId: id });
    }

    TabSelect = (tabKey) => {
        this.setState({tabKey})
    }

    render() {
        return (
            <div className="d-flex flex-column" id="content-wrapper" >
                <div id="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6"><h3 className="text-dark mb-1">Administracion</h3></div>
                            <div className="col-sm-6"><ResidentialSelect onDropdownSelected={this.onResidentialChanged}/></div>
                        </div>
                        
                        <div>
                            <hr />
                        </div>
                        {this.state.selectedResidentialId > 0 &&
                            <div className="row m-2 d-flex flex-column justify-content">
                                <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" onSelect={this.TabSelect}>
                                    <Tab eventKey="report" title="Informes">
                                        <AdminReports
                                            residentialId={this.state.tabKey == "report" ? this.state.selectedResidentialId : 0}
                                            abbreviation={this.state.tabKey == "report" ? this.state.residential.abbreviation : ""}
                                        />
                                    </Tab>
                                    <Tab eventKey="reportType" title="Tipos de Informe">
                                        <AdminReportTypes />
                                    </Tab>
                                    <Tab eventKey="visitType" title="Tipos de Visita">
                                        <AdminVisitTypes />
                                    </Tab>
                                    <Tab eventKey="building" title="Filiales">
                                        <AdminBuildings residentialId={this.state.tabKey == "building" ? this.state.selectedResidentialId : 0} />
                                    </Tab>
                                    <Tab eventKey="users" title="Usuarios">
                                        <AdminUsers residentialId={this.state.tabKey == "users" ? this.state.selectedResidentialId : 0} />
                                    </Tab>
                                    <Tab eventKey="resources" title="Recursos">
                                        <AdminResources residentialId={this.state.tabKey == "resources" ? this.state.selectedResidentialId : 0} />
                                    </Tab>
                                    <Tab eventKey="news" title="Noticias">
                                        <AdminNews newsType="0" residentialId={this.state.tabKey == "news" ? this.state.selectedResidentialId : 0} />
                                    </Tab>
                                    <Tab eventKey="reminders" title="Recordatorios">
                                        <AdminNews newsType="1" residentialId={this.state.tabKey == "reminders" ? this.state.selectedResidentialId : 0} />
                                    </Tab>
                                    <Tab eventKey="equipment" title="Equipo">
                                        <AdminEquipmentLoans residentialId={this.state.tabKey == "equipment" ? this.state.selectedResidentialId : 0} />
                                    </Tab>
                                    <Tab eventKey="reservations" title="Reservaciones">
                                        <ReservationHistory residentialId={this.state.tabKey == "reservations" ? this.state.selectedResidentialId : 0} />
                                    </Tab>                                    

                                    {false &&
                                        <>
                                            <Tab eventKey="reminders" title="Recordatorios">
                                                <AdminReminders residentialId={this.state.tabKey == "reminders" ? this.state.selectedResidentialId : 0} />
                                            </Tab>
                                            <Tab eventKey="news" title="Reglamento">
                                                Reglamento
                                            </Tab>

                                        </>
                                    }
                                    </Tabs>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Admin;