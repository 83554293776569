import React from 'react';
import Storage from '../common/Storage';
import axios from 'axios';
import Constants from '../common/Constants';
import SimpleModal from '../common/SimpleModal';

class AdminReportTypes extends React.Component {
    constructor(props) {
        super(props);

        let user = Storage.getItem('CurrentUser');

        this.state = {
            user,
            reportTypeList: [],
            newId: 0,
            savedModalShow: false
        };
    }

    loadReportTypes = () => {
        axios.get(Constants.URL_API + 'ReportType/GetAll', { headers: Storage.getAuth() })
            .then(res => {
                let reportTypeList = res.data;
                reportTypeList.sort((a, b) => (a.name > b.name ? 1 : -1));
                this.setState({ reportTypeList });
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
    }
    
    reloadData = () => {
        this.loadReportTypes();
    }

    componentDidMount() {
        this.reloadData();
        if (this.props.setSave) { this.props.setSave(this.Save); }
    }

    /*componentDidUpdate(prevProps, prevState) {
        if (prevProps.buildingId == this.props.buildingId && prevProps.residentialId == this.props.residentialId) { return; }

        this.reloadData();
    }
    */

    Delete = (r) => {
        this.PropertyUpdate(r, { rowStatus: Constants.STATUS_DELETE });
    }

    Add = () => {
        let reportTypeList = this.state.reportTypeList;
        let newId = this.state.newId - 1;

        reportTypeList.unshift({ reportTypeId: newId, name: "", rowStatus: Constants.STATUS_NEW });
        this.setState({ reportTypeList, newId });
    }
    
    PropertyUpdate = (r, newObj) => {
        let reportTypeList = this.state.reportTypeList;
        let reportType = reportTypeList.find((i) => i.reportTypeId === r.reportTypeId);
        Object.assign(reportType, { rowStatus: Constants.STATUS_EDIT }, newObj);
        this.setState(reportTypeList);
    }
    
    ChangeName = (r, e) => {
        this.PropertyUpdate(r, { name: e.target.value });
    }
    
    Save = () => {
        this.Create();
        this.Update();
        this.Delete();
        this.setState({ savedModalShow: true });
    }

    CleanStatus = () => {
        let reportTypeList = this.state.reportTypeList;
        reportTypeList.forEach(u => u.rowStatus = null);
        this.setState({ reportTypeList });
    }

    

    Create = () => {
        let list = this.state.reportTypeList.filter(u => u.reportTypeId < 0 && u.rowStatus == Constants.STATUS_EDIT);
        this.SaveAPI("CreateMultiple", "post", list);
    }
    
    Update = () => {
        let list = this.state.reportTypeList.filter(u => u.reportTypeId > 0 && u.rowStatus == Constants.STATUS_EDIT);
        this.SaveAPI("UpdateMultiple", "put", list);
    }

    Delete = () => {
        let list = this.state.reportTypeList.filter(u => u.reportTypeId > 0 && u.rowStatus == Constants.STATUS_DELETE);
        let ids = list.map(u => u.reportTypeId);
        this.SaveAPI("DeleteMultiple", "delete", ids);
    }
    
    SaveAPI = (action, method, data) => {
        if (data.length == 0) { return; }
        axios(Constants.URL_API + 'ReportType/' + action, { method, data, headers: Storage.getAuth() })
            .then(res => {
                //this.setState({ userList: res.data });
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
    }
    
    render() {
        return (
            <div>
                <SimpleModal body="Los cambios se han guardado correctamente" okText="Gracias!" show={this.state.savedModalShow} onClose={() => { this.setState({ savedModalShow: false }); this.loadReportTypes(); }} />
                <h4>
                    <span className="mr-2">Tipos de Informe</span>
                    <button className="btn btn-sm btn-success" onClick={this.Add}><span className="fa fa-plus"></span></button>
                    <button className="btn btn-sm btn-primary" onClick={this.Save}><span className="fa fa-save"></span></button>
                </h4>

                <div className="row">
                    <div className="col-sm-8">Nombre</div>
                    <div className="col-sm-4">Acciones</div>
                </div>

                {this.state.reportTypeList.map((r, i) => 
                    <div key={r.reportTypeId} className={"row " + (r.rowStatus == Constants.STATUS_EDIT ? "bg-warning" : (r.rowStatus == Constants.STATUS_NEW ? "bg-success" : ""))} style={{ display: r.rowStatus != Constants.STATUS_DELETE ? '' : 'none' }}>
                        <div className="col-sm-8"><input className="form-control" type="text" value={r.name} onChange={(e) => { this.ChangeName(r, e); }} /></div>
                        <div className="col-sm-4">
                            <button className="btn btn-sm btn-danger" onClick={() => { this.Delete(r); }} value={r.reportTypeId}><span className="fa fa-trash"></span></button>
                        </div>
                    </div>
                    )
                }
            </div>
        );
    }
}

export default AdminReportTypes;