import React from 'react';
import Storage from '../common/Storage';
import axios from 'axios';
import Constants from '../common/Constants';
import SimpleModal from '../common/SimpleModal';
import DataTable from 'react-data-table-component';
import Moment from 'react-moment';
import AdminEquipmentLoanItem from '../admin/AdminEquipmentLoanItem';

class AdminEquipmentLoans extends React.Component {
    constructor(props) {
        super(props);

        let user = Storage.getItem('CurrentUser');
        this.state = {
            user,
            search:"",
            equipmentTypeList: ["Otro","Gafete", "Tarjeta"],
            equipmentLoanList: [],
            newId: 0,
            savedModalShow: false,
            showModalEquipmentLoan: false,
            columns: [
                {
                    name: 'Tipo', selector: 'equipmentType', sortable: true, cell: row => (
                        <span>{(row.equipmentType <= this.state.equipmentTypeList.length ? this.state.equipmentTypeList[row.equipmentType] : "Otro")}</span>
                    )
                },
                { name: 'Nombre', selector: 'name', sortable: true },
                { name: 'Prestado a', selector: 'recipient', sortable: true },
                { name: 'Prestado por', selector: 'userGiver.name', sortable: true },
                {
                    name: 'Prestado el', selector: 'givenTime', sortable: true, cell: row => (
                        <Moment format="DD/MM/YYYY hh:mm A" subtract={{ hours: 6 }}>
                            {row.givenTime}
                        </Moment>
                    )   
                },
                { name: 'Detalles', selector: 'details', sortable: true, right: true },
                {
                    name: 'Retornado a', selector: 'userReceiver', sortable: true, right: true, cell: row => (
                        row.userReceiver && <>{row.userReceiver.name}</>
                        )
                },
                {
                    name: 'Retornado el', selector: 'returnedTime', sortable: true, right: true, cell: row => (
                            (new Date(row.returnedTime)).getFullYear() > 2000 &&
                            <>
                            <Moment format="DD/MM/YYYY hh:mm A" subtract={{ hours: 6 }}>
                                {row.returnedTime}
                            </Moment>
                            </>
                    )   
                },

                {
                    name: 'Estado', selector: 'userReceiver', sortable: true, right: true, cell: row => (
                        <span>{(row.userReceiver ? "Devuelto" : "Pendiente" )}</span>
                    )
                },
                {
                    name: 'Acciones', right: true, cell: row => (
                        <div>
                            <button className="btn btn-sm btn-primary" onClick={() => { this.ShowModalEquipmentLoan(row); }}><span className="fa fa-reply-all"></span></button>
                            {false && <button className="btn btn-sm btn-danger" onClick={() => { this.ShowModalDelete(row); }}><span className="fa fa-trash"></span></button> }
                        </div>
                    )
                },
            ],
        };
    }

    loadEquipmentTypes = () => {
        //let equipmentTypeList = [{id:1,name:"Badge"}]
        //this.setState({ equipmentTypeList });
    }

    loadEquipmentLoans = () => {
        axios.get(Constants.URL_API + 'EquipmentLoan/GetAll?id=' + this.props.residentialId, { headers: Storage.getAuth() })
            .then(res => {
                let equipmentLoanList = res.data;
                equipmentLoanList.sort((a, b) => (a.GivenTime > b.GivenTime ? 1 : -1));
                this.setState({ equipmentLoanList });
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
    }
    
    reloadData = () => {
        this.loadEquipmentTypes();
        this.loadEquipmentLoans();
    }
    
    componentDidMount() {
        //if (this.props.setSave) { this.props.setSave(this.Save); }
        //console.log("test" + this.props.residentialId);
        this.reloadData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.residentialId == this.props.residentialId) { return; }

        this.reloadData();
    }

    openModalNew = () => {
        this.setState({
            currentEquipmentLoan: {
                residential: { residentialId: this.props.residentialId },
                equipmentType: 1,
                name: ""
            },
            showModalEquipmentLoan: true,
            modalOkText: "Guardar cambios",
            modalCancelText: "Descartar cambios",
            action: Constants.STATUS_NEW
        });
    }

    modalOkClick = () => {
        this.SaveEquipmentLoan();
    }

    OnEquipmentLoanSaved = () => {
        this.reloadData();
    }

    ShowModalEquipmentLoan = (equipmentLoan) => {
        this.setState({
            currentEquipmentLoan: equipmentLoan,
            showModalEquipmentLoan: true,
            modalOkText: "Aceptar devolucion",
            modalCancelText: "Descartar cambios",
            action: Constants.STATUS_EDIT
        });
    }

    SearchChanged = (e) => {
        let search = e.target.value;
        this.setState({ search });
    }

    /*
    Delete = (r) => {
        this.PropertyUpdate(r, { rowStatus: Constants.STATUS_DELETE });
    }

    Add = () => {
        let visitTypeList = this.state.visitTypeList;
        let newId = this.state.newId - 1;

        visitTypeList.unshift({ visitTypeId: newId, name: "", rowStatus: Constants.STATUS_NEW });
        this.setState({ visitTypeList, newId });
    }
    
    PropertyUpdate = (r, newObj) => {
        let visitTypeList = this.state.visitTypeList;
        let visitType = visitTypeList.find((i) => i.visitTypeId === r.visitTypeId);
        Object.assign(visitType, { rowStatus: Constants.STATUS_EDIT }, newObj);
        this.setState(visitTypeList);
    }
    
    ChangeName = (r, e) => {
        this.PropertyUpdate(r, { name: e.target.value });
    }
    
    Save = () => {
        this.Create();
        this.Update();
        this.Delete();
        this.setState({ savedModalShow: true });
    }

    CleanStatus = () => {
        let visitTypeList = this.state.visitTypeList;
        visitTypeList.forEach(u => u.rowStatus = null);
        this.setState({ visitTypeList });
    }

    

    Create = () => {
        let list = this.state.visitTypeList.filter(u => u.visitTypeId < 0 && u.rowStatus == Constants.STATUS_EDIT);
        this.SaveAPI("CreateMultiple", "post", list);
    }
    
    Update = () => {
        let list = this.state.visitTypeList.filter(u => u.visitTypeId > 0 && u.rowStatus == Constants.STATUS_EDIT);
        this.SaveAPI("UpdateMultiple", "put", list);
    }

    Delete = () => {
        let list = this.state.visitTypeList.filter(u => u.visitTypeId > 0 && u.rowStatus == Constants.STATUS_DELETE);
        let ids = list.map(u => u.visitTypeId);
        this.SaveAPI("DeleteMultiple", "delete", ids);
    }
    
    SaveAPI = (action, method, data) => {
        if (data.length == 0) { return; }
        axios(Constants.URL_API + 'VisitType/' + action, { method, data, headers: Storage.getAuth() })
            .then(res => {
                //this.setState({ userList: res.data });
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
    }
    */
    render() {
        return (
            <div>
                <SimpleModal body="Los cambios se han guardado correctamente" okText="Gracias!" show={this.state.savedModalShow} onClose={() => { this.setState({ savedModalShow: false }); this.reloadData(); }} />
                <SimpleModal dialogClassName="modal-90w" show={this.state.showModalEquipmentLoan} okClick={this.modalOkClick} okText={this.state.modalOkText} cancelText={this.state.modalCancelText} onClose={() => { this.setState({ showModalEquipmentLoan: false }) }}>
                    <AdminEquipmentLoanItem equipmentLoan={this.state.currentEquipmentLoan} action={this.state.action} setSave={s => this.SaveEquipmentLoan = s} onEquipmentLoanSaved={this.OnEquipmentLoanSaved} />
                </SimpleModal>

                <h4>
                    <span className="mr-2">Prestamo de equipo</span>
                    <button className="btn btn-sm btn-success" onClick={this.openModalNew}><span className="fa fa-plus"></span></button>
                </h4>
                
                <div className="row">
                    <div className="col-8">&nbsp;</div>
                    <div className="col-4">
                        <div className="input-group rounded">
                            <input type="search" className="form-control rounded" placeholder="Buscar..." aria-label="Buscar..." aria-describedby="search-addon" onChange={this.SearchChanged} />
                            <span className="input-group-text border-0" id="search-addon">
                                <i className="fas fa-search"></i>
                            </span>
                        </div>
                    </div>
                </div>
                
                <DataTable
                    columns={this.state.columns}
                    data={this.state.equipmentLoanList.filter(i =>
                        !this.state.search ||
                        i.name.toLowerCase().includes(this.state.search.toLowerCase()) ||
                        this.state.equipmentTypeList[i.equipmentType].toLowerCase().includes(this.state.search.toLowerCase()) ||
                        i.recipient.toLowerCase().includes(this.state.search.toLowerCase()) ||
                        i.userGiver.name.toLowerCase().includes(this.state.search.toLowerCase()) ||
                        i.details.toLowerCase().includes(this.state.search.toLowerCase())
                    )}
                    pagination={true}
                />
            </div>
        );
    }
}

export default AdminEquipmentLoans;