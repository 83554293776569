import React from 'react';
import axios from 'axios';
import Constants from '../common/Constants';

class Support extends React.Component {
    constructor(props) {
        super(props);

        let inquiry = { name: "", email: "", comments: "", status:0 };
        this.state = { inquiry };
    }

    NameChange = (e) => {
        let inquiry = this.state.inquiry;
        inquiry.name = e.target.value;
        this.setState({ inquiry });
    }

    EmailChange = (e) => {
        let inquiry = this.state.inquiry;
        inquiry.email = e.target.value;
        this.setState({ inquiry });
    }

    CommentsChange = (e) => {
        let inquiry = this.state.inquiry;
        inquiry.comments = e.target.value;
        this.setState({ inquiry });
    }

    btnSubmitClick = (e) => {
        let inquiry = this.state.inquiry;

        axios.post(Constants.URL_API + 'Support/GetSupportMailbox', inquiry)
            .then(res => {
                console.log(res);
                inquiry.status = res.data.length > 0 ? 1 : 2;
                
                //this.setState({ showModal: true });
                //if (this.props.OnSave) { this.props.OnSave(); }
                if (inquiry.status == 1) { this.sendInquiry(); }
                
                this.setState({ inquiry });
            })
            .catch((error) => {
                inquiry.status = 2;
                this.setState({ inquiry });

                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
    }
    
    sendInquiry = (e) => {
        let inquiry = this.state.inquiry;

        axios.post(Constants.URL_API + 'Support/New', inquiry)
            .then(res => {
                inquiry.status = 1;
                this.setState({ inquiry });
            })
            .catch((error) => {
                inquiry.status = 2;
                this.setState({ inquiry });

                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
    }

    render() {
        return (
            <div className="d-flex flex-column" id="content-wrapper" >
                <div id="content">
                    <div className="container-fluid">
                        <h3 className="text-dark mb-1">&#191;Necesita Soporte? &iexcl;Estamos para ayudarle!</h3>
                        <h3 className="name mt-5"><i className="fa fa-wrench icon" style={{ fontSize: '50px' }}></i></h3>

                        <div className="row">
                            <div className="col-sm-2">Nombre: </div>
                            <div className="col-sm-10">
                                <input autoFocus value={this.state.inquiry.name} onChange={this.NameChange} className="form-control" type="text" id="name" placeholder="Nombre" name="Nombre" disabled={ this.state.inquiry.status > 0} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-2">Email: </div>
                            <div className="col-sm-10">
                                <input value={this.state.inquiry.email} onChange={this.EmailChange} className="form-control" type="text" id="email" placeholder="Email" name="Email" disabled={this.state.inquiry.status > 0} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-2">Comentarios: </div>
                            <div className="col-sm-10">
                                <textarea className="form-control" value={this.state.inquiry.comments} onChange={this.CommentsChange} rows="5" placeholder="Comentarios" disabled={this.state.inquiry.status > 0} />
                            </div>
                        </div>
                        {this.state.inquiry.status == 0 &&
                            <button visible="false" onClick={this.btnSubmitClick} className="btn btn-primary btn-block text-white btn-user" type="submit" >Enviar</button>
                        }
                        {this.state.inquiry.status == 1 &&
                            <p>Estaremos atendiendo su consulta y nos comunicaremos pronto con la respuesta.</p>
                        }
                        {this.state.inquiry.status == 2 &&
                            <p>Para atenderle de forma correcta en su caso particular, por favor comun&iacute;quese con la administraci&oacute;n al tel&eacute;fono <a href="tel:8987-1192">8987-1192</a>.</p>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
export default Support