import React from 'react';
import Storage from '../common/Storage';
import axios from 'axios';
import Constants from '../common/Constants';

class AdminReportItem extends React.Component {
    constructor(props) {
        super(props);

        let report = this.props.report;
        this.state = {
            report,
            reportTypeList: [],
            uploadProgress: 0
        };
    }

    componentDidMount() {
        this.props.setSave(this.Save);
        this.loadReportTypes();
    }

    Save = () => {
        if (this.props.action == Constants.STATUS_EDIT) { this.Update(); }
        if (this.props.action == Constants.STATUS_NEW) { this.Create(); }
    }

    Update = () => {
        this.SaveAPI("Update", "put", this.state.report);
    }
    Create = () => {
        this.SaveAPI("New", "post", this.state.report);
    }

    SaveAPI = (action, method, data) => {
        axios(Constants.URL_API + 'Report/' + action, { method, data, headers: Storage.getAuth() })
            .then(res => {
                if (this.props.onReportSaved) { this.props.onReportSaved(); }
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
    }

    NameChanged = (e) => {
        let report = this.state.report;
        report.name = e.target.value;
        this.setState({ report});
    }

    DescriptionChanged = (e) => {
        let report = this.state.report;
        report.description = e.target.value;
        this.setState({ report});
    }

    ReportStatusChanged = (e) => {
        let report = this.state.report;
        report.reportStatus = parseInt(e.target.value);
        this.setState({ report});
    }

    ReportTypeChanged = (e) => {
        let report = this.state.report;
        let reportType = this.state.reportTypeList.find((i) => i.reportTypeId == e.target.value);
        report.reportTypeR = reportType;
        this.setState({ report }); 
    }

    FileChanged = (e) => {
        let file = e.target.files[0];
        let prefix = this.props.abbreviation + "-";
        let newFileName = (file.name.startsWith(prefix) ? "" : prefix) + file.name
        var newFile = new File([file], newFileName, {
            type: file.type,
        });
        //console.log(file);
        //console.log(this.props);        

        let report = this.state.report;
        report.name = newFileName; //file.name;
        this.setState({ report, uploadProgress:0 });

        const data = new FormData();
        
        data.append('file', newFile);
        let config = {
            headers: Storage.getAuth(),
            onUploadProgress: progressEvent => {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                this.setState({ uploadProgress: percentCompleted});
            }
        };
        axios.post(Constants.URL_API + 'File/UploadReport', data, config)
        .then(res => { // then print response status
            console.log(res.statusText);
        })
    }

    loadReportTypes = () => {
        axios.get(Constants.URL_API + 'ReportType/GetAll', { headers: Storage.getAuth() })
            .then(res => {
                let reportTypeList = res.data;
                reportTypeList.sort((a, b) => (a.name > b.name ? 1 : -1));
                this.setState({ reportTypeList });
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col-sm-4">Nombre: </div>
                    <div className="col-sm-8">
                        <input type="text" className="form-control" value={this.state.report.name} onChange={this.NameChanged} readOnly="true" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">Tipo: </div>
                    <div className="col-sm-8">
                        <select className="form-control" value={this.state.report.reportTypeR ? this.state.report.reportTypeR.reportTypeId : 0} onChange={(e) => this.ReportTypeChanged(e)}>
                            <option disabled value="0"> -- Elija un tipo -- </option>
                            {
                                this.state.reportTypeList.map((i) => 
                                    <option value={i.reportTypeId}>{i.name}</option>
                                )
                            }
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">Descripcion: </div>
                    <div className="col-sm-8">
                        <input type="text" className="form-control" value={this.state.report.description} onChange={this.DescriptionChanged} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">Estado: </div>
                    <div className="col-sm-8">
                        <select className="form-control" defaultValue={this.state.report.reportStatus} onChange={(e) => this.ReportStatusChanged(e)}>
                            <option value="0">Borrador</option>
                            <option value="1">Publicado</option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">Archivo: &nbsp;
                        {this.state.uploadProgress ? <span>{this.state.uploadProgress + "%"}</span> : ""}
                    </div>
                    <div className="col-sm-8">
                        <input className="form-control" type="file" name="file" onChange={this.FileChanged} />
                    </div>
                    
                </div>
            </>
        );
    }
}

export default AdminReportItem;