import React from 'react';
import axios from 'axios';
import Constants from '../common/Constants';
import Storage from '../common/Storage';

class BuildingSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = { BuildingList: [] }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.forceRefresh && this.state.BuildingList.length > 0 && this.props.residentialId === prevProps.residentialId) { return; }

        let residentialId = (this.props.residentialId ? this.props.residentialId : 0);
        axios.get(Constants.URL_API + 'Building/GetAll?id=' + residentialId, { headers: Storage.getAuth() })
            .then(res => {
                let list = res.data.sort((x, y) => {
                    if (x.code < y.code) { return -1; }
                    if (x.code > y.code) { return 1; }
                    return 0;
                });
                this.setState({ BuildingList: list });
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
    }

    render() {
        return (
            <>
                <div className="form-group">
                    <label htmlFor="BuildingId">Filial</label>
                    <select className="form-control" id="BuildingId" type="select" defaultValue="0" onChange={this.props.onDropdownSelected}>
                        <option disabled value="0"> -- Elija una opcion -- </option>
                        {this.state.BuildingList.map((e, key) => {
                            return <option key={key} value={JSON.stringify(e)} >{e.code}</option>;
                        })}
                    </select>
                </div>
            </>
        );
    }
}

export default BuildingSelect;