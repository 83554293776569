import React from 'react';
import Storage from '../common/Storage';
import axios from 'axios';
import Constants from '../common/Constants';
import SimpleModal from '../common/SimpleModal';
import Dropdown from 'react-bootstrap/Dropdown'
import TimePicker from 'react-time-picker';

class AdminResourceLock extends React.Component {
    constructor(props) {
        super(props);

        let lockList = props.resource.locks;

        lockList.map(l => {
            l.timeStart = l.timeStart.slice(l.timeStart.length - 8, l.timeStart.length)
            l.timeEnd = l.timeEnd.slice(l.timeEnd.length - 8, l.timeEnd.length)
        });

        this.state = {
            lockList,
            newId: 0,
        };

        this.setSave = props.setSave;
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        //console.log(prevProps);
        //if (prevProps.residentialId == this.props.residentialId) { return; }
        //this.RefreshResources();
    }

    ResourceChangeResLimitedWeekdaysFlags = (r, e) => {
        let dayOfWeek = r.dayOfWeek ^ parseInt(e.target.value);
        this.ResourcePropertyUpdate(r, { dayOfWeek });
    }

    ResourcePropertyUpdate = (r, newObj) => {
        let lockList = this.state.lockList;
        let item = lockList.find((i) => i.resourceLockId === r.resourceLockId);
        Object.assign(item, { rowStatus: Constants.STATUS_EDIT }, newObj);
        this.setState({ lockList });
        var resource = this.props.resource;
        resource.locks = this.state.lockList;
        this.setSave(resource);
    }

    LockDelete = (r) => {
        //this.ResourcePropertyUpdate(r, { rowStatus: Constants.STATUS_DELETE });
        let lockList = this.state.lockList;
        for (var i = 0; i < lockList.length; i++) {
            if (lockList[i].resourceLockId === r.resourceLockId)
            { lockList.splice(i, 1);   }
        }
        var resource = this.props.resource;
        resource.locks = lockList;
        this.setSave(resource);
    }

    LockAdd = () => {
        let lockList = this.state.lockList;
        let newId = this.state.newId - 1;
        lockList.unshift({ resourceLockId: newId, rowStatus: Constants.STATUS_NEW, timeStart: "00:00", timeEnd: "00:00", dayOfWeek: 0 });
        this.setState({ lockList, newId });
    }

    LockChangeStartTime = (r, e) => {this.ResourcePropertyUpdate(r, { timeStart: e });}
    LockChangeEndTime = (r, e) => { this.ResourcePropertyUpdate(r, { timeEnd: e }); }

    Save = () => {
        // remove flagged for deletion
        let lockList = this.state.lockList.filter(u => u.rowStatus != Constants.STATUS_DELETE);

        // get Resource Object
        let resource = this.props.resource;

        // set lock list
        resource.locks = lockList;

        // call update
        this.SaveAPI("Update", "PUT", resource);
    }

    SaveAPI = (action, method, data) => {
        if (data.length == 0) { return; }
        console.log(data);
        return;

        axios(Constants.URL_API + 'Resource/' + action, { method, data, headers: Storage.getAuth() })
            .then(res => {
                //this.setState({ userList: res.data });
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
    }
    /*


Create = () => {
    let list = this.state.resourceList.filter(u => u.resourceId < 0 && u.rowStatus == Constants.STATUS_EDIT);
    this.SaveAPI("CreateMultiple", "post", list);
}

Update = () => {
    let list = this.state.resourceList.filter(u => u.resourceId > 0 && u.rowStatus == Constants.STATUS_EDIT);
    this.SaveAPI("UpdateMultiple", "put", list);
}

Delete = () => {
    let list = this.state.resourceList.filter(u => u.resourceId > 0 && u.rowStatus == Constants.STATUS_DELETE);
    let ids = list.map(u => u.resourceId);
    this.SaveAPI("DeleteMultiple", "delete", ids);
}



*/

    render() {
        return <>
            <div className="card bg-light" >
                <div className="card-body ">
                    <h6>
                        <span className="mr-4">Horas de bloqueo</span>
                        <button className="btn btn-sm btn-success" onClick={this.LockAdd}><span className="fa fa-plus"></span></button>
                    </h6>
                    <div>
                        <div className="row">
                            <div className="col-sm-3">Hora de inicio </div>
                            <div className="col-sm-3">Hora final </div>
                            <div className="col-sm-3">Dias de la semana</div>
                            <div className="col-sm-3">Acciones</div>
                        </div>

                        {this.state.lockList.map((r, i) =>
                            <div key={r.resourceLockId} className={"row " + (r.rowStatus == Constants.STATUS_EDIT ? "" : (r.rowStatus == Constants.STATUS_NEW ? "bg-success" : ""))} style={{ display: r.rowStatus != Constants.STATUS_DELETE ? '' : 'none' }} >
                                <div className="col-sm-3">
                                    <TimePicker className="form-control" onChange={(e) => { this.LockChangeStartTime(r, e); }} value={r.timeStart} clockIcon={null} />
                                </div>
                                <div className="col-sm-3">
                                    <TimePicker className="form-control" onChange={(e) => { this.LockChangeEndTime(r, e); }} value={r.timeEnd} clockIcon={null} />
                                </div>
                                <div className="col-sm-3">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            {(r.dayOfWeek & 1) > 0 && <span>L</span>}
                                            {(r.dayOfWeek & 2) > 0 && <span>K</span>}
                                            {(r.dayOfWeek & 4) > 0 && <span>M</span>}
                                            {(r.dayOfWeek & 8) > 0 && <span>J</span>}
                                            {(r.dayOfWeek & 16) > 0 && <span>V</span>}
                                            {(r.dayOfWeek & 32) > 0 && <span>S</span>}
                                            {(r.dayOfWeek & 64) > 0 && <span>D</span>}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item><button className="btn btn-sm btn-default" value="1" onClick={(e) => { this.ResourceChangeResLimitedWeekdaysFlags(r, e); }}>Lunes</button></Dropdown.Item>
                                            <Dropdown.Item><button className="btn btn-sm btn-default" value="2" onClick={(e) => { this.ResourceChangeResLimitedWeekdaysFlags(r, e); }}>Martes</button></Dropdown.Item>
                                            <Dropdown.Item><button className="btn btn-sm btn-default" value="4" onClick={(e) => { this.ResourceChangeResLimitedWeekdaysFlags(r, e); }}>Miercoles</button></Dropdown.Item>
                                            <Dropdown.Item><button className="btn btn-sm btn-default" value="8" onClick={(e) => { this.ResourceChangeResLimitedWeekdaysFlags(r, e); }}>Jueves</button></Dropdown.Item>
                                            <Dropdown.Item><button className="btn btn-sm btn-default" value="16" onClick={(e) => { this.ResourceChangeResLimitedWeekdaysFlags(r, e); }}>Viernes</button></Dropdown.Item>
                                            <Dropdown.Item><button className="btn btn-sm btn-default" value="32" onClick={(e) => { this.ResourceChangeResLimitedWeekdaysFlags(r, e); }}>Sabado</button></Dropdown.Item>
                                            <Dropdown.Item><button className="btn btn-sm btn-default" value="64" onClick={(e) => { this.ResourceChangeResLimitedWeekdaysFlags(r, e); }}>Domingo</button></Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div className="col-sm-3">
                                    <button className="btn btn-sm btn-danger" onClick={(e) => { this.LockDelete(r); } }><span className="fa fa-trash"></span></button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>;
    }
}

export default AdminResourceLock;