import React from 'react';
import Storage from '../common/Storage';
import axios from 'axios';
import Constants from '../common/Constants';
import SimpleModal from '../common/SimpleModal';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import FroalaEditor from 'react-froala-wysiwyg';

class AdminReminders extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            savedModalShow: false,
            reminder: ""            
        };
    }

    GetReminder = () => {
        axios.get('/assets/appfiles/reports/' + this.GetFileName())
            .then(res => { // then print response status
                //console.log(res);
                this.setState({ reminder: res.data });
            })
            .catch((error) => {
                let reminder = "<i>No se han creado recordatorios para este condominio</i>";
                this.setState({reminder});

                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
        /*
        axios.get(Constants.URL_API + 'Report/GetAll?Id=' + this.props.residentialId, { headers: Storage.getAuth() })
            .then(res => {
                let data = res.data.map(i => {
                    i.createdDate = i.createdDate.substring(i.createdDate, 10);
                    i.reportTypeRName = (i.reportTypeR ? i.reportTypeR.name : "");
                    return i;
                });
                this.setState({ reportList: data });
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
            */
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.residentialId == this.props.residentialId) { return; }

        this.GetReminder();
    }

    GetFileName = () => { return "Futurecr_Reminder_" + this.props.residentialId + ".html"; }

    Save = () => {
        let reminderName = this.GetFileName();
        
        const data = new FormData();

        data.append('file', new Blob([this.state.reminder]), reminderName);
        let config = {
            headers: Storage.getAuth(),
            onUploadProgress: progressEvent => {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                this.setState({ uploadProgress: percentCompleted });
            }
        };
        
        axios.post(Constants.URL_API + 'File/UploadReport', data, config)
            .then(res => { // then print response status
                console.log(res.statusText);
            });

        this.OnSaved();
    }

    OnSaved = () => {
        this.setState({ savedModalShow: true });
    }

    ContentChanged = (e) => {
        let reminder = e;//.target.value;
        this.setState({ reminder });
    }

    render() {
        return (
            <div>
                <h4>
                    <span className="mr-2">Recordatorios</span>
                </h4>
                <SimpleModal body="Los cambios se han guardado correctamente" okText="Gracias!" show={this.state.savedModalShow} onClose={() => { this.setState({ savedModalShow: false }); }} />                
                <FroalaEditor
                    tag='textarea'
                    config={this.config}
                    model={this.state.reminder}
                    onModelChange={this.ContentChanged}
                />
                <br/>
                <button className="btn btn-xl btn-primary" onClick={ this.Save }>Guardar</button>
            </div>
        );
    }
}

export default AdminReminders;