import React from 'react';
import Storage from '../common/Storage';
import axios from 'axios';
import Constants from '../common/Constants';
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import FroalaEditor from 'react-froala-wysiwyg';

class AdminNewsItem extends React.Component {
    constructor(props) {
        super(props);

        let news = this.props.news;
        news.push = false;
        this.state = {
            news
        };
    }

    componentDidMount() {
        this.props.setSave(this.Save);
    }

    Save = () => {
        if (this.props.action == Constants.STATUS_EDIT) { this.Update(); }
        if (this.props.action == Constants.STATUS_NEW) { this.Create(); }
    }

    Update = () => {
        this.SaveAPI("Update", "put", this.state.news);
    }
    Create = () => {
        this.SaveAPI("New", "post", this.state.news);
    }

    SaveAPI = (action, method, news) => {
        let data = Object.assign({}, news);
        data.publishStart = moment(data.publishStart).format().substr(0, 19);
        data.publishEnd = moment(data.publishEnd).format().substr(0, 19);
        data.newsType = parseInt(this.props.newsType);

        axios(Constants.URL_API + 'News/' + action, { method, data, headers: Storage.getAuth() })
            .then(res => {
                if (this.props.onNewsSaved) { this.props.onNewsSaved(); }
                if (news.push) {
                    console.log(res.data);
                    let id = 0;
                    switch (action) {
                        case 'Update': id = news.newsId; break;
                        case 'New': id = res.data; break;
                    }
                    axios(Constants.URL_API + 'News/Push?id=' + id, { method: "post", data, headers: Storage.getAuth() })
                        .then(pp => { })
                        .catch(ff => { });
                }
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
    }

    TitleChanged = (e) => {
        let news = this.state.news;
        news.title = e.target.value;
        this.setState({ news });
    }

    ContentChanged = (e) => {
        let news = this.state.news;
        news.content = e;//.target.value;
        this.setState({ news });
    }

    PublishStartChanged = (e) => {
        let news = this.state.news;
        news.publishStart = e;
        this.setState({ news });
    }

    PublishEndChanged = (e) => {
        let news = this.state.news;
        news.publishEnd = e;
        this.setState({ news });
    }

    PushChanged = (e) => {
        let news = this.state.news;
        news.push = e.target.checked;
        this.setState({ news });
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col-sm-4">Titulo: </div>
                    <div className="col-sm-8">
                        <input type="text" className="form-control" value={this.state.news.title} onChange={this.TitleChanged} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">Contenido: </div>
                    <div className="col-sm-8">
                        
                        <FroalaEditor
                            tag='textarea'
                            config={this.config}
                            model={this.state.news.content}
                            onModelChange={this.ContentChanged}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">Inicio publicacion: </div>
                    <div className="col-sm-8">
                        <DateTimePicker className="form-control" value={this.state.news.publishStart} onChange={this.PublishStartChanged} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">Fin publicacion: </div>
                    <div className="col-sm-8">
                        <DateTimePicker className="form-control" value={this.state.news.publishEnd} onChange={this.PublishEndChanged} />
                    </div>
                </div>
                {this.props.newsType == 0 && <div className="row">
                    <div className="col-sm-4">Notificar al guardar: </div>
                    <div className="col-sm-8">
                        <input type="checkbox" defaultChecked={this.state.news.push} onChange={this.PushChanged} ></input>
                    </div>
                </div>
                }
            </>
        );
    }
}

export default AdminNewsItem;