import React from 'react';
import SimpleModal from '../common/SimpleModal';
import AdminNewsItem from './AdminNewsItem';
import DataTable from 'react-data-table-component';
import Storage from '../common/Storage';
import axios from 'axios';
import Constants from '../common/Constants';

class AdminNews extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentNews: {},
            showModalNews: false,
            newsList: [],
            columns: [
                { name: 'Titulo', selector: 'title', sortable: true },
                {
                    name: 'Inicio Publicacion', sortable: true, right: true, cell: row => (
                        <div>{row.publishStart.toLocaleString('es-MX', { 'dateStyle': 'short' }) }</div>
                    )
                },
                {
                    name: 'Fin Publicacion', sortable: true, right: true, cell: row => (
                        <div>{row.publishEnd.toLocaleString('es-MX', { 'dateStyle': 'short'})}</div>
                    )
                },
                { name: 'Creado', selector: 'createdDate', sortable: true, right: true },
                { name: 'Modificado', selector: 'modifiedDate', sortable: true, right: true },
                {
                    name: 'Acciones', right: true, cell: row => (
                        <div>
                            <button hidden={ true } className="btn btn-sm btn-primary" onClick={() => { this.SendPush(row); }}><span className="fa fa-mobile"></span></button>
                            <button className="btn btn-sm btn-warning" onClick={() => { this.ShowModalNews(row); }}><span className="fa fa-pencil"></span></button>
                            <button className="btn btn-sm btn-danger" onClick={() => { this.ShowModalDelete(row); }}><span className="fa fa-trash"></span></button>
                        </div>
                    )
                },
            ],
        };
    }

    ShowModalDelete = (r) => {
        let currentNews = r;
        this.setState({ deleteModalShow: true, currentNews });
    }

    ShowModalNews = (r) => {
        let currentNews = r;
        this.setState({ showModalNews: true, currentNews, action: Constants.STATUS_EDIT });
    }

    SendPush = (r) => {
        axios(Constants.URL_API + 'News/Push?id=' + r.newsId, { method: "post", r, headers: Storage.getAuth() })
            .then(pp => { })
            .catch(ff => { });
    }

    DoDelete = () => {
        axios.delete(Constants.URL_API + 'News/Delete?id=' + this.state.currentNews.newsId, { headers: Storage.getAuth() })
            .then(res => {
                this.UpdateNews();
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
    }

    UpdateNews = () => {
        axios.get(Constants.URL_API + 'News/GetAll?Id=' + this.props.residentialId + "&newsType=" + this.props.newsType, { headers: Storage.getAuth() })
            .then(res => {
                let data = res.data.map(i => {
                    i.createdDate = i.createdDate.substring(i.createdDate, 10);
                    i.modifiedDate = i.modifiedDate.substring(i.createdDate, 10);
                    //i.publishStart = i.publishStart.substring(i.publishStart, 10);
                    //i.publishEnd = i.publishEnd.substring(i.publishEnd, 10);
                    i.publishStart = new Date(i.publishStart);
                    i.publishEnd = new Date(i.publishEnd);
                    return i;
                });
                this.setState({ newsList: data });
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({ apiCode: error.response.status });
                } else if (error.request) {
                    this.setState({ apiCode: 0 });
                } else {
                    this.setState({ apiCode: 0 });
                    console.log('Error', error.message);
                }
            });
    }

    modalOkClick = () => {
        this.SaveNews();
    }

    OnNewsSaved = () => {
        this.setState({ savedModalShow: true });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.residentialId == this.props.residentialId) { return; }

        this.UpdateNews();
    }

    openModalNew = () => {
        this.setState({
            currentNews: {
                title: "",
                residential: { residentialId: this.props.residentialId },
                content: ""
            },
            showModalNews: true,
            action: Constants.STATUS_NEW
        });
    }

    render() {
        return (
            <div>
                <h4>
                    <span className="mr-2">{this.props.newsType == 0 ? "Noticias" : "Recordatorios"}</span>
                    <button className="btn btn-sm btn-success" onClick={this.openModalNew}><span className="fa fa-plus"></span></button>
                </h4>
                <SimpleModal body="Los cambios se han guardado correctamente" okText="Gracias!" show={this.state.savedModalShow} onClose={() => { this.setState({ savedModalShow: false }); this.UpdateNews(); }} />
                <SimpleModal body="Esta seguro que desea eliminar este elemento?" okClick={this.DoDelete} okText="Si, borrar" cancelText="No borrar" show={this.state.deleteModalShow} onClose={() => { this.setState({ deleteModalShow: false }); this.UpdateNews(); }} />
                <SimpleModal dialogClassName="modal-90w" action={this.state.action} show={this.state.showModalNews} okClick={this.modalOkClick} okText="Guardar" cancelText="Cancelar" onClose={() => { this.setState({ showModalNews: false }) }}>
                    <AdminNewsItem news={this.state.currentNews} action={this.state.action} setSave={s => this.SaveNews = s} onNewsSaved={this.OnNewsSaved} newsType={ this.props.newsType } />
                </SimpleModal>
                <DataTable
                    columns={this.state.columns}
                    data={this.state.newsList}
                    pagination={true}
                />
            </div>
        );
    }
}

export default AdminNews;