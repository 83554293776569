import React from 'react';
//import logo from './logo.svg';
import Routes from './Routes';
import './App.css';

function App() {
    /*
     * <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to be happier. Or not
        </p>
        <a className="App-link" href="https://reactjs.org" target="_blank" rel="noopener noreferrer">
                  Learn React
        </a>

      </header>
     */
  return (
      <div className="App">
          <Routes />
    </div>
  );
}

export default App;
