import React from 'react';

function Soon()
{
    return (
        <div className = "d-flex flex-column" id = "content-wrapper" >
            <div id="content">
                <div className="container-fluid">
                <h3 className="text-dark mb-1">Zona en construccion... muy pronto!</h3>
                <h3 className="name mt-5"><i className="fa fa-wrench icon" style={{ fontSize: '200px' }}></i></h3>
                </div>
            </div>
        </div>
    );
}

export default Soon